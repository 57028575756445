<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>
<section class="pt-4">
    <div class="container center pt-1">
        <!-- Combinaison 1 : Budget faible (<5k€) + Oui/Oui -->
        <h3 *ngIf="session.budget <= 5000 && session.step1.response === '01' && session.step3.response === '01'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Avec seulement <span class="color-important-word">{{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                }}</span>, boostez vos premières importations et générez vos premiers bénéfices.
        </h3>

        <!-- Combinaison 2 : Budget faible (<5k€) + Oui/Non -->
        <h3 *ngIf="session.budget <= 5000 && session.step1.response === '01' && session.step3.response === '02'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Transformez votre idée en un business rentable : Commencez dans l'import-export avec {{ session.budget |
            currency:'EUR':'symbol':'1.0-0':'fr' }}.
        </h3>

        <!-- Combinaison 3 : Budget faible (<5k€) + Non/Oui -->
        <h3 *ngIf="session.budget <= 5000 && session.step1.response === '02' && session.step3.response === '01'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Découvrez les produits gagnants et utilisez vos {{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr' }}
            pour lancer vos premières commandes.
        </h3>

        <!-- Combinaison 4 : Budget faible (<5k€) + Non/Non -->
        <h3 *ngIf="session.budget <= 5000 && session.step1.response === '02' && session.step3.response === '02'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Commencez dans l'import-export avec {{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr' }} : Le guide
            parfait pour débutants sans expérience.
        </h3>

        <!-- Combinaison 5 : Budget moyen (5k€ – 50k€) + Oui/Oui -->
        <h3 *ngIf="session.budget > 5000 && session.budget <= 50000 && session.step1.response === '01' && session.step3.response === '01'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Exploitez le potentiel de vos {{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr' }} pour maximiser vos
            marges dès votre première commande.
        </h3>

        <!-- Combinaison 6 : Budget moyen (5k€ – 50k€) + Oui/Non -->
        <h3 *ngIf="session.budget > 5000 && session.budget <= 50000 && session.step1.response === '01' && session.step3.response === '02'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Avec {{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr' }}, lancez un business structuré et démarrez
            vos premières importations.
        </h3>

        <!-- Combinaison 7 : Budget moyen (5k€ – 50k€) + Non/Oui -->
        <h3 *ngIf="session.budget > 5000 && session.budget <= 50000 && session.step1.response === '02' && session.step3.response === '01'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Développez votre société d'import-export et découvrez des produits rentables avec {{ session.budget |
            currency:'EUR':'symbol':'1.0-0':'fr' }}.
        </h3>

        <!-- Combinaison 8 : Budget moyen (5k€ – 50k€) + Non/Non -->
        <h3 *ngIf="session.budget > 5000 && session.budget <= 50000 && session.step1.response === '02' && session.step3.response === '02'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Lancez-vous dans l'import-export avec {{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr' }} et
            démarrez un business rentable.
        </h3>

        <!-- Combinaison 9 : Budget élevé (>50k€) + Oui/Oui -->
        <h3 *ngIf="session.budget > 50000 && session.step1.response === '01' && session.step3.response === '01'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Optimisez vos {{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr' }} et générez un retour sur
            investissement maximal grâce à notre guide avancé.
        </h3>

        <!-- Combinaison 10 : Budget élevé (>50k€) + Oui/Non -->
        <h3 *ngIf="session.budget > 50000 && session.step1.response === '01' && session.step3.response === '02'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Transformez vos <span class="color-important-word">{{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                }}</span> en un business structuré et rentable dès vos premières importations.
        </h3>

        <!-- Combinaison 11 : Budget élevé (>50k€) + Non/Oui -->
        <h3 *ngIf="session.budget > 50000 && session.step1.response === '02' && session.step3.response === '01'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Utilisez vos <span class="color-important-word">{{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                }}</span> pour découvrir des opportunités produits et maximiser vos bénéfices.
        </h3>

        <!-- Combinaison 12 : Budget élevé (>50k€) + Non/Non -->
        <h3 *ngIf="session.budget > 50000 && session.step1.response === '02' && session.step3.response === '02'"
            class="display-4 fw-bolder ls1 mb-1 position-relative">
            Investissez vos <span class="color-important-word">{{ session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                }}</span> dans un projet solide et découvrez comment <span class="color-important-word">réussir</span>
            dans <span class="color-important-word">l'import-export.</span>
        </h3>
    </div>
</section>



<!-- Section video -->
<section class="video-vsl">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="clear"></div>
                <div class="text-center">
                    <div class="wistia_responsive_padding mb-5" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_sb92z7iycd seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/sb92z7iycd/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="d-none d-md-block">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class=" d-block d-md-none">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-mini button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill"></i>OBTENIR LE GUIDE</a>
                    </div>
                </div>
                <div class="row mt-5 " style="background-color: #222F3E; border-radius: 40px;">
                    <div class="col-md-3 center pt-4">
                        <span class="fw-bolder text-white">17,643</span>
                        <div class="rating-stars" tabindex="0">

                            <span class="filled-stars" style="width: 20%;">
                                <span style="color: yellow;" class="star">
                                    <span class="bi-star-fill"></span>
                                </span>
                                <span style="color: yellow;" class="star">
                                    <span class="bi-star-fill"></span>
                                </span>
                                <span style="color: yellow;" class="star">
                                    <span class="bi-star-fill"></span>
                                </span>
                                <span style="color: yellow;" class="star">
                                    <span class="bi-star-fill"></span>
                                </span>
                                <span style="color: yellow;" class="star">
                                    <span class="bi-star-fill"></span>
                                </span>


                            </span>
                        </div>
                    </div>
                    <div class="col-md-5 center pt-4">
                        <p class="text-white">
                            Satisfied e-commerce owners have already increased their conversion rates using my
                            E-commerce Checklist.
                        </p>
                    </div>
                    <div class="col-md-3 center pt-4 mb-5">

                        <div class="circle-stack d-flex justify-content-center" style="--cnvs-circle-stack-size: 3rem;">
                            <img src="assets/images/ebooks/ebook05/avatar/1.jpg" alt="Avatar Image">
                            <img src="assets/images/ebooks/ebook05/avatar/2.jpg" alt="Avatar Image">
                            <img src="assets/images/ebooks/ebook05/avatar/3.jpg" alt="Avatar Image">
                            <img src="assets/images/ebooks/ebook05/avatar/4.jpg" alt="Avatar Image">
                            <img src="assets/images/ebooks/ebook05/avatar/5.jpg" alt="Avatar Image">
                            <a href="#" class="h-bg-contrast-900 h-text-contrast-100">
                                <i class="bi-bag"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="douleur-section">
    <div class="container">
        <div class="text-center mb-4">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                Importer de Chine : ce que vous ne savez pas peut vous coûter très cher !
            </h6>
            <!-- Contenu visible uniquement sur les smartphones -->
            <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none douleur-section-text-title">
                Eviter tout les pieges de l'import export
            </h6>
        </div>
        <!-- <img src="assets/images/ebooks/ebook05/watch.png"> -->
    </div>
</section>
<div class="scroll-container mb-5 pt-5">
    <div class="scroll-track" #scrollTrack>

        <div class="scroll-item  text-center align-items-stretch d-flex">
            <div class="stat-card ">
                <div class="stat-card  mb-5">
                    <img src="assets/images/ebooks/ebook05/1svg.svg">
                </div>
                <div class="stat-card  pt-2">
                    <img src="assets/images/ebooks/ebook05/2svg.svg">
                </div>
            </div>
        </div>
        <div class="scroll-item  text-center align-items-stretch d-flex">
            <div class="stat-card pt-6 ">
                <div class="stat-card  mb-5">
                    <img src="assets/images/ebooks/ebook05/3svg.svg">
                </div>
            </div>
        </div>
        <div class="scroll-item  text-center align-items-stretch d-flex">
            <div class="stat-card ">
                <div class="stat-card  mb-5">
                    <img src="assets/images/ebooks/ebook05/4svg.svg">
                </div>
                <div class="stat-card  pt-2">
                    <img src="assets/images/ebooks/ebook05/5svg.svg">
                </div>
            </div>
        </div>
        <div class="scroll-item  text-center align-items-stretch d-flex">
            <div class="stat-card pt-2 ">
                <div class="stat-card  mb-5">
                    <img src="assets/images/ebooks/ebook05/6svg.svg">
                </div>
            </div>
        </div>
        <div class="scroll-item  text-center align-items-stretch d-flex">
            <div class="stat-card ">
                <div class="stat-card  mb-5">
                    <img src="assets/images/ebooks/ebook05/8svg.svg">
                </div>
                <div class="stat-card  pt-2">
                    <img src="assets/images/ebooks/ebook05/9svg.svg">
                </div>
            </div>
        </div>
        <div class="scroll-item  text-center align-items-stretch d-flex">
            <div class="stat-card pt-6 ">
                <div class="stat-card  mb-5">
                    <img src="assets/images/ebooks/ebook05/7svg.svg">
                </div>
            </div>
        </div>
    </div>
</div>
<section class="douleur-section">
    <div class="text-center">
        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
        <div class="d-none d-md-block pt-4" style="margin-bottom: 50px;">
            <a (click)="scrollToElement('section-pricing')"
                class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                    class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
        </div>
        <!-- Contenu visible uniquement sur les smartphones -->
        <div class="d-block d-md-none pt-5" style="margin-bottom: -50px;">
            <a (click)="scrollToElement('section-pricing')"
                class="button button-mini button-circle button-green fw-bolder call-to-action-background-color"><i
                    class="bi-arrow-down-right-circle-fill"></i>OBTENIR LE GUIDE</a>
        </div>
    </div>
</section>

<!-- Présentation de l'ebook -->
<section class="solution py-5" style="background-color: #FFD600;">
    <div class="container text-center">
        <h2 class="display-4 fw-bolder">Notre guide te donne les clés pour passer à l'action, étape par étape</h2>
        <p class="lead mt-3">Découvre les étapes pour importer intelligemment et développer ton business rapidement.</p>
        <!-- <ul class="list-unstyled mt-4">
            <li>✅ Trouve les meilleurs fournisseurs chinois.</li>
            <li>✅ Maîtrise les Incoterms et règles douanières.</li>
            <li>✅ Maximise ton retour sur investissement.</li>
        </ul>
        <img src="images/ebook.png" alt="Ebook Import-Export" class="img-fluid mt-4"> -->
        <div class="row justify-content-center col-mb-50 align-items-stretch">


            <div class="clear"></div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                    <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">01</div>
                    <h2 class="mb-3 text-black fw-bolder">Trouve les meilleurs fournisseurs chinois.</h2>
                    <p class="text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique beatae
                        excepturi, nesciunt
                        exercitationem obcaecati sapiente reprehenderit expedita!</p>
                    <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                    <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">02</div>
                    <h2 class="mb-3 text-black fw-bolder">Maîtrise les Incoterms et règles douanières.</h2>
                    <p class="text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique beatae
                        excepturi, nesciunt
                        exercitationem obcaecati sapiente reprehenderit expedita!</p>
                    <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                    <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">03</div>
                    <h2 class="mb-3 text-black fw-bolder">Maximise ton retour sur investissement</h2>
                    <p class="text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique beatae
                        excepturi, nesciunt
                        exercitationem obcaecati sapiente reprehenderit expedita!</p>
                    <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                </div>
            </div>



        </div>
        <div class="text-center pt-4">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <div class="d-none d-md-block pt-4" style="margin-bottom: 0px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-xlarge button-circle button-green fw-bolder call-to-action-v2 "><i
                        class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
            </div>
            <!-- Contenu visible uniquement sur les smartphones -->
            <div class="d-block d-md-none pt-5" style="margin-bottom: -0px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-mini button-circle button-green fw-bolder call-to-action-v2 "><i
                        class="bi-arrow-down-right-circle-fill"></i>OBTENIR LE GUIDE</a>
            </div>
        </div>

    </div>
</section>

<!-- Desktop version -->
<!-- Produit 01 -->
<!-- Quelque cas pratique et concret pour ton investissement de -->
<section class="d-none d-md-block">
    <div class="container">
        <div class="col-md-12 text-center pt-5">
            <h2 class="display-4 fw-bolder">Quelque cas pratique et concret pour ton investissement de {{session.budget
                | currency:'EUR':'symbol':'1.0-0':'fr'}} !</h2>
            <hr>
        </div>
        <div class="row gy-5 justify-content-center col-mb-50 align-items-stretch">
            <div class="col-md-5">
                <div class="align-self-center">
                    <h2 class="lh-sm mb-4 fw-bolder">Le kit Iphone</h2>
                    <img class="pt-3" src="assets/images/ebooks/ebook05/chine.png" width="50px"><br>
                    <span class="ms-0 op-06 small">Achat en Chine</span><br>
                    <span class="ms-0 op-06 small">DDP : Livraison frais de douane compris</span>
                    <h3 class="lh-sm mb-4 fs-3">Prix d'achat : 8.50€ DDP</h3>
                    <div class="line my-4"></div>
                    <img src="assets/images/ebooks/ebook05/france.png" width="50px">&nbsp;&nbsp;<img
                        src="assets/images/ebooks/ebook05/europe.png" width="50px"><br>
                    <span class="ms-0 op-06 small">Vente en France et/ou en Europe</span><br>
                    <span class="ms-0 op-06 small">Nombre de piece à vendre : {{product01.sellingQuantity}}</span>
                    <h3 class="lh-sm mb-4 fs-3 ">Prix de vente : Entre 27.00€ et 50.00€</h3>

                    <div class="line my-4"></div>
                    <h4 class="fw-bolder">
                        Avec ton investissement de <span class="display-6 fw-bolder color-important-word">{{
                            session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                            }}</span>, tu transformes ton effort en un incroyable gain de <span
                            class="display-6 fw-bolder color-important-word">{{ product01.profit |
                            currency:'EUR':'symbol':'1.0-0':'fr' }}</span>. Imagine ce que tu pourrais accomplir ensuite
                        !
                    </h4>
                    <div>
                        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                        <div class="d-none d-md-block pt-4" style="margin-bottom: 50px;">
                            <a (click)="scrollToElement('section-pricing')"
                                class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                    class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
                        </div>                    
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <img src="assets/images/ebooks/ebook05/1-1.jpg" class="rounded-6" alt="...">
            </div>
        </div>
    </div>
</section>
<!-- phone version -->
<!-- Produit 01 -->
<!-- Quelque cas pratique et concret pour ton investissement de -->
<section class="d-block d-md-none">
    <div class="container">
        <div class="col-md-12 text-center pt-5">
            <h2 class="display-4 fw-bolder">Quelque cas pratique et concret pour ton investissement de {{session.budget
                | currency:'EUR':'symbol':'1.0-0':'fr'}} !</h2>
            <hr>
        </div>
        <div class="row gy-5 justify-content-center col-mb-50 align-items-stretch center">
            <div class="col-12">
                <div class="align-self-center">
                    <h2 class="lh-sm mb-4 fw-bolder">Le kit Iphone</h2>
                    <img class="pt-3" src="assets/images/ebooks/ebook05/chine.png" width="50px"><br>
                    <span class="ms-0 op-06 small">Achat en Chine</span><br>
                    <span class="ms-0 op-06 small">DDP : Livraison frais de douane compris</span>
                    <h3 class="lh-sm mb-4 fs-3">Prix d'achat : 8.50€ DDP</h3>
                    <img src="assets/images/ebooks/ebook05/1-1.jpg" class="rounded-6" alt="...">
                    <!-- <div class="line my-4"></div>                     -->
                    <img src="assets/images/ebooks/ebook05/france.png" width="50px">&nbsp;&nbsp;<img
                        src="assets/images/ebooks/ebook05/europe.png" width="50px"><br>
                    <span class="ms-0 op-06 small">Vente en France et/ou en Europe</span><br>
                    <span class="ms-0 op-06 small">Nombre de piece à vendre : {{product01.sellingQuantity}}</span>
                    <h3 class="lh-sm mb-4 fs-3 ">Prix de vente : Entre 27.00€ et 50.00€</h3>

                    <div class="line my-4"></div>
                    <h4 class="fw-bolder">
                        Avec ton investissement de <span class="display-6 fw-bolder color-important-word">{{
                            session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                            }}</span>, tu transformes ton effort en un incroyable gain de <span
                            class="display-6 fw-bolder color-important-word">{{ product01.profit |
                            currency:'EUR':'symbol':'1.0-0':'fr' }}</span>. Imagine ce que tu pourrais accomplir ensuite
                        !
                    </h4>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- desktop version -->
<!-- Produit 02 -->
<!-- Produit pneu -->
<section class="d-none d-md-block" style="background-color: #000000;">
    <div class="container">

        <div class="row gy-5 justify-content-center col-mb-50 align-items-stretch">
            <div class="col-md-4 pt-6">
                <img src="assets/images/ebooks/ebook05/1-2.jpg" class="rounded-6 pt-4" alt="...">
            </div>
            <div class="col-md-6">
                <div class="align-self-center">
                    <h2 class="lh-sm mb-4 fw-bolder text-white">Pneu de voiture</h2>
                    <img class="pt-3" src="assets/images/ebooks/ebook05/chine.png" width="50px"><br>
                    <span class="ms-0 op-06 small text-white">Achat en Chine</span><br>
                    <span class="ms-0 op-06 small text-white">DDP : Livraison frais de douane compris</span>
                    <h3 class="lh-sm mb-4 fs-3 text-white">Prix d'achat : 27€ DDP</h3>
                    <div class="line my-4"></div>
                    <img src="assets/images/ebooks/ebook05/france.png" width="50px">&nbsp;&nbsp;<img
                        src="assets/images/ebooks/ebook05/europe.png" width="50px"><br>
                    <span class="ms-0 op-06 small text-white">Vente en France et/ou en Europe</span><br>
                    <span class="ms-0 op-06 small text-white">Nombre de piece à vendre :
                        {{product02.sellingQuantity}}</span>
                    <h3 class="lh-sm mb-4 fs-3 text-white">Prix de vente : Entre 57.00€ et 87.00€</h3>

                    <div class="line my-4"></div>
                    <h4 class="fw-bolder text-white">
                        Avec ton investissement de <span class="display-6 fw-bolder color-important-word">{{
                            session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                            }}</span>, tu transformes ton effort en un incroyable gain de <span
                            class="display-6 fw-bolder color-important-word">{{ product02.profit |
                            currency:'EUR':'symbol':'1.0-0':'fr' }}</span>. Imagine ce que tu pourrais accomplir ensuite
                        !
                    </h4>
                    <div>
                        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                        <div class="d-none d-md-block pt-4" style="margin-bottom: 50px;">
                            <a (click)="scrollToElement('section-pricing')"
                                class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                    class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
                        </div>                    
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- phone version -->
<!-- Produit 02 -->
<!-- Produit pneu -->
<section class="d-block d-md-none" style="background-color: #000000;">
    <div class="container">
        <div class="row gy-5 justify-content-center col-mb-50 align-items-stretch center">
            <div class="col-12">
                <div class="align-self-center">
                    <h2 class="lh-sm mb-4 fw-bolder text-white">Pneu de voiture</h2>
                    <img class="pt-3" src="assets/images/ebooks/ebook05/chine.png" width="50px"><br>
                    <span class="ms-0 op-06 small text-white">Achat en Chine</span><br>
                    <span class="ms-0 op-06 small text-white">DDP : Livraison frais de douane compris</span>
                    <h3 class="lh-sm mb-4 fs-3 text-white">Prix d'achat : 27.00€ DDP</h3>
                    <img src="assets/images/ebooks/ebook05/1-2.jpg" class="rounded-6" alt="...">
                    <div class="line my-4"></div>
                    <img src="assets/images/ebooks/ebook05/france.png" width="50px">&nbsp;&nbsp;<img
                        src="assets/images/ebooks/ebook05/europe.png" width="50px"><br>
                    <span class="ms-0 op-06 small text-white">Vente en France et/ou en Europe</span><br>
                    <span class="ms-0 op-06 small text-white">Nombre de piece à vendre :
                        {{product02.sellingQuantity}}</span>
                    <h3 class="lh-sm mb-4 fs-3 text-white">Prix de vente : Entre 57.00€ et 87.00€</h3>

                    <div class="line my-4"></div>
                    <h4 class="fw-bolder text-white">
                        Avec ton investissement de <span class="display-6 fw-bolder color-important-word">{{
                            session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                            }}</span>, tu transformes ton effort en un incroyable gain de <span
                            class="display-6 fw-bolder color-important-word">{{ product02.profit |
                            currency:'EUR':'symbol':'1.0-0':'fr' }}</span>. Imagine ce que tu pourrais accomplir ensuite
                        !
                    </h4>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- desktop version -->
<!-- Produit 03 -->
<!-- Produit couverture -->
<section class="d-none d-md-block">
    <div class="container">
        <div class="row gy-5 justify-content-center col-mb-50 align-items-stretch">
            <div class="col-md-5">
                <div class="align-self-center pt-6">
                    <h2 class="lh-sm mb-4 fw-bolder">Couverture</h2>
                    <img class="pt-3" src="assets/images/ebooks/ebook05/chine.png" width="50px"><br>
                    <span class="ms-0 op-06 small">Achat en Chine</span><br>
                    <span class="ms-0 op-06 small">DDP : Livraison frais de douane compris</span>
                    <h3 class="lh-sm mb-4 fs-3">Prix d'achat : 10.50€ DDP</h3>
                    <div class="line my-4"></div>
                    <img src="assets/images/ebooks/ebook05/france.png" width="50px">&nbsp;&nbsp;<img
                        src="assets/images/ebooks/ebook05/europe.png" width="50px"><br>
                    <span class="ms-0 op-06 small">Vente en France et/ou en Europe</span><br>
                    <span class="ms-0 op-06 small">Nombre de piece à vendre : {{product03.sellingQuantity}}</span>
                    <h3 class="lh-sm mb-4 fs-3 ">Prix de vente : Entre 17.00€ et 27.00€</h3>

                    <div class="line my-4"></div>
                    <h4 class="fw-bolder">
                        Avec ton investissement de <span class="display-6 fw-bolder color-important-word">{{
                            session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                            }}</span>, tu transformes ton effort en un incroyable gain de <span
                            class="display-6 fw-bolder color-important-word">{{ product03.profit |
                            currency:'EUR':'symbol':'1.0-0':'fr' }}</span>. Imagine ce que tu pourrais accomplir ensuite
                        !
                    </h4>  
                    <div>
                        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                        <div class="d-none d-md-block pt-4" style="margin-bottom: 50px;">
                            <a (click)="scrollToElement('section-pricing')"
                                class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                    class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
                        </div>                    
                    </div>                  
                </div>
            </div>
            <div class="col-md-5 pt-6">
                <img src="assets/images/ebooks/ebook05/1-3.jpg" class="rounded-6 pt-4" alt="...">
            </div>
        </div>
    </div>
</section>

<!-- phone version -->
<!-- Produit 03 -->
<!-- Produit couverture -->
<section class="d-block d-md-none">
    <div class="container">
        <div class="row gy-5 justify-content-center col-mb-50 align-items-stretch center">
            <div class="col-12">
                <div class="align-self-center">
                    <h2 class="lh-sm mb-4 fw-bolder">Couverture</h2>
                    <img class="pt-3" src="assets/images/ebooks/ebook05/chine.png">
                    <span class="ms-0 op-06 small">Achat en Chine</span><br>
                    <span class="ms-0 op-06 small">DDP : Livraison frais de douane compris</span>
                    <h3 class="lh-sm mb-4 fs-3">Prix d'achat : 10.50€ DDP</h3>
                    <img src="assets/images/ebooks/ebook05/1-3.jpg" class="rounded-6" alt="...">
                    <div class="line my-4"></div>
                    <img src="assets/images/ebooks/ebook05/france.png" width="50px">&nbsp;&nbsp;<img
                        src="assets/images/ebooks/ebook05/europe.png" width="50px"><br>
                    <span class="ms-0 op-06 small">Vente en France et/ou en Europe</span><br>
                    <span class="ms-0 op-06 small">Nombre de piece à vendre : {{product03.sellingQuantity}}</span>
                    <h3 class="lh-sm mb-4 fs-3">Prix de vente : Entre 17.00€ et 27.00€</h3>

                    <div class="line my-4"></div>
                    <h4 class="fw-bolder">
                        Avec ton investissement de <span class="display-6 fw-bolder color-important-word">{{
                            session.budget | currency:'EUR':'symbol':'1.0-0':'fr'
                            }}</span>, tu transformes ton effort en un incroyable gain de <span
                            class="display-6 fw-bolder color-important-word">{{ product03.profit |
                            currency:'EUR':'symbol':'1.0-0':'fr' }}</span>. Imagine ce que tu pourrais accomplir ensuite
                        !
                    </h4>
                </div>
            </div>
        </div>
    </div>
</section>
<section style="background-color: #000000;">
    <!-- <div class="content-wrap" style="margin-bottom: -10px;"> -->
        <div class="container center pt-5 mb-5">
            <h2 class="display-4 fw-bolder text-white">Le contact de soucing pour assurer la qualité de chaque produit que tu va importer !</h2>
            <!-- <p class="lead mt-3 text-white">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p> -->
            <!-- <div class="row justify-content-center mw-md mx-auto"> -->
                <img src="assets/images/ebooks/ebook05/controle-de-qualite.png" width="110px" class="mb-5">
            <!-- </div> -->
        </div>
    <!-- </div> -->
</section>
<section>
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">La Chine : Ton meilleur allié pour l'importation</h2>
                    <p class="lead mb-5">Découvre les spécificités de la culture et des négociations commerciales en
                        Chine.
                        Identifie les marchés clés : Yiwu, Canton, et autres hubs stratégiques.
                        Apprends à voyager et faire des affaires efficacement (visa, interprètes, astuces locales)..</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('images/blocks/preview/featured/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('images/blocks/preview/featured/2.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('images/blocks/preview/featured/3.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook05/3.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="content">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('images/blocks/preview/featured/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('images/blocks/preview/featured/2.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('images/blocks/preview/featured/3.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook05/3.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">Transitaires : Le maillon essentiel de ton succès</h2>
                    <p class="lead mb-5">Pourquoi un transitaire est indispensable pour sécuriser tes envois.
                        Comment choisir le bon partenaire pour minimiser tes risques.
                        Des recommandations fiables pour la France et au-delà.</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <h2 class="text-center py-4 bg-contrast-100 fs-1 fw-bold border-top border-bottom border-contrast-200" data-class="dark:bg-contrast-200 dark:border-contrast-300">
    <span class="d-block my-2 nocolor">Tu vas avoir accès au contact direct de 12 transitaires pour la gestion de ta marchindise partout en dans le monde !</span>
</h2> -->
<section style="background-color: #000000;">
    <div class="content-wrap">
        <div class="container center">
            <h2 class="display-4 fw-bolder text-white">Le contact direct de 12 transitaires pour assurer tes
                importantions en France et partout en Europe !</h2>
            <!-- <p class="lead mt-3 text-white">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p> -->
            <div class="row justify-content-center mw-md mx-auto">
                <img src="assets/images/ebooks/ebook05/amazon.png">
            </div>
        </div>
    </div>
</section>


<section>
    <div class="content-wrap">
        <div class="container">
            <div class="row justify-content-center mw-md mx-auto">

                <!-- Titre et Description -->
                <div class="col-md-12 text-center my-0">
                    <h2 class="display-4 fw-bolder">Tout est source de business avec l'import / export</h2>
                    <p class="lead mt-3">Les opportuinité sont infinit et la Chine ........</p>
                </div>


                <div class="row gallery-categories gutter-20">
                    <div class="col-md-7">
                        <div
                            style="background: url('assets/images/ebooks/ebook05/gallery-4/1.jpg') no-repeat center center; background-size: cover; height: 250px;">
                            <div class="vertical-middle p-4 dark" data-bs-theme="dark">
                                <div class="heading-block m-0 border-0 w-50">
                                    <h3 class="text-capitalize font-secondary text-transform-none fw-bold h1 mb-3">
                                        Travel</h3>
                                    <a href="#" class="more-link border-light text-light mb-0 stretched-link">Shop Now
                                        <i class="bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div
                            style="background: url('assets/images/ebooks/ebook05/gallery-4/2.jpg') no-repeat center center; background-size: cover; height: 250px;">
                            <div class="vertical-middle p-4">
                                <div class="heading-block m-0 border-0 w-50">
                                    <h3 class="text-capitalize font-secondary text-transform-none fw-bold h1 mb-3">
                                        Headphones</h3>
                                    <a href="#" class="more-link border-dark text-dark mb-0 stretched-link">Shop Now <i
                                            class="bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div
                            style="background: url('assets/images/ebooks/ebook05/gallery-4/3.jpg') no-repeat 50% 40%; background-size: cover; height: 250px;">
                            <div class="vertical-middle p-4">
                                <div class="heading-block m-0 border-0 w-50">
                                    <h3 class="text-capitalize font-secondary text-transform-none fw-bold h1 mb-3">
                                        Watches</h3>
                                    <a href="#" class="more-link border-dark text-dark mb-0 stretched-link">Shop Now <i
                                            class="bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div
                            style="background: url('assets/images/ebooks/ebook05/gallery-4/4.jpg') no-repeat 100% 100%; background-size: cover; height: 250px;">
                            <div class="vertical-middle p-4">
                                <div class="heading-block m-0 border-0 w-50">
                                    <h3 class="text-capitalize font-secondary text-transform-none fw-bold h1 mb-3">Bags
                                    </h3>
                                    <a href="#" class="more-link border-dark text-dark mb-0 stretched-link">Shop Now <i
                                            class="bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div
                            style="background: url('assets/images/ebooks/ebook05/gallery-4/5.jpg') no-repeat 20% 50%; background-size: cover; height: 250px;">
                            <div class="vertical-middle p-4">
                                <div class="heading-block m-0 border-0 w-50">
                                    <h3 class="text-capitalize font-secondary text-transform-none fw-bold h1 mb-3">
                                        Drones</h3>
                                    <a href="#" class="more-link border-dark text-dark mb-0 stretched-link">Shop Now <i
                                            class="bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div
                            style="background: url('assets/images/ebooks/ebook05/gallery-4/6.jpg') no-repeat 50% 80%; background-size: cover; height: 250px;">
                            <div class="vertical-middle p-4">
                                <div class="heading-block m-0 border-0 w-50">
                                    <h3 class="text-capitalize font-secondary text-transform-none fw-bold h1 mb-3">
                                        Lenses</h3>
                                    <a href="#" class="more-link border-dark text-dark mb-0 stretched-link">Shop Now <i
                                            class="bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-7">
                        <div
                            style="background: url('assets/images/ebooks/ebook05/gallery-4/7.jpg') no-repeat center center; background-size: cover; height: 250px;">
                            <div class="vertical-middle p-4">
                                <div class="heading-block m-0 border-0 w-50">
                                    <h3 class="text-capitalize font-secondary text-transform-none fw-bold h1 mb-3">Shoes
                                    </h3>
                                    <a href="#" class="more-link border-dark text-dark mb-0 stretched-link">Shop Now <i
                                            class="bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section class="solution py-5" style="background-color: #FFD600;">
    <div class="container text-center">
        <h2 class="display-4 fw-bolder">Optimise tes coûts logistiques : Chaque euro compte !</h2>
        <p class="lead mt-3">Découvre les étapes pour importer intelligemment et développer ton business rapidement.</p>
        <!-- <ul class="list-unstyled mt-4">
            <li>✅ Trouve les meilleurs fournisseurs chinois.</li>
            <li>✅ Maîtrise les Incoterms et règles douanières.</li>
            <li>✅ Maximise ton retour sur investissement.</li>
        </ul>
        <img src="images/ebook.png" alt="Ebook Import-Export" class="img-fluid mt-4"> -->
        <div class="row justify-content-center col-mb-50 align-items-stretch">


            <div class="clear"></div>

            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                    <!-- <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">01</div> -->
                    <h2 class="mb-3 text-black fw-bolder">Fais le bon choix entre transport maritime, aérien ou
                        ferroviaire</h2>

                    <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                    <!-- <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">02</div> -->
                    <h2 class="mb-3 text-black fw-bolder">Découvre comment le groupage peut réduire considérablement tes
                        frais.
                    </h2>
                    <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                    <!-- <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">03</div> -->
                    <h2 class="mb-3 text-black fw-bolder">Valide ton produit et évite les risques</h2>
                    <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                </div>
            </div>



        </div>
        <div class="text-center pt-4">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <div class="d-none d-md-block pt-4" style="margin-bottom: 0px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-xlarge button-circle button-green fw-bolder call-to-action-v2 "><i
                        class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
            </div>
            <!-- Contenu visible uniquement sur les smartphones -->
            <div class="d-block d-md-none pt-5" style="margin-bottom: -0px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-mini button-circle button-green fw-bolder call-to-action-v2 "><i
                        class="bi-arrow-down-right-circle-fill"></i>OBTENIR LE GUIDE</a>
            </div>
        </div>

    </div>
</section>


<section id="content">
    <div class="content-wrap">
        <div class="container">
            <div class="row justify-content-center col-mb-50 align-items-stretch">
                <div class="col-md-12 text-center">
                    <h2 class="display-4 fw-bolder">Incoterms : Maîtrise les règles internationales du commerce</h2>
                    <p class="text-larger text-muted">Best-Selling &amp; Most Trusted HTML5 Template. Experience the
                        Ever-Growing Feature Rich Template since 2014.</p>
                </div>
                <div class="clear"></div>
                <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                    <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                        <!-- <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">01</div> -->
                        <h2 class="mb-3 text-black fw-bolder">Comprends chaque type d'Incoterm et choisis celui qui te
                            convient.</h2>

                        <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                    <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                        <!-- <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">02</div> -->
                        <h2 class="mb-3 text-black fw-bolder">Pourquoi l'Incoterm FOB (Free on Board) est souvent le
                            choix optimal.
                        </h2>
                        <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                    <div class="d-flex flex-column p-4 rounded-3 shadow-lg bg-light dark">
                        <!-- <div class="text-smaller px-1 bg-danger dark rounded-1 mb-3 d-inline-flex align-self-start">03</div> -->
                        <h2 class="mb-3 text-black fw-bolder">Des exemples pratiques pour t'aider à négocier comme un
                            pro.</h2>
                        <!-- <img src="demos/covid-care/images/illustration/support.svg" alt="Feature Icon"> -->
                    </div>
                </div>



            </div>
            <div class="row justify-content-center align-items-stretch mw-lg mx-auto gutter-20">

                <div class="w-100 mt-4"></div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="bi-mouse"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>EXW </h3>
                            <p class="my-0">( Free Carrier )</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="uil uil-ellipsis-h"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>FCA</h3>
                            <p class="my-0">( Free Carrier )</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="bi-file-earmark-richtext"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>DAP</h3>
                            <p class="my-0">( Delivered At Place )</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="bi-code"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>FOB </h3>
                            <p class="my-0">( Free On Board )</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="bi-box-seam"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>CFR </h3>
                            <p class="my-0">( Cost and Freight )</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="bi-layout-sidebar-inset-reverse"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>CIF</h3>
                            <p class="my-0">( Cost, Insurance and Freight )</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="bi-people"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>DDP</h3>
                            <p class="my-0">( Delivered Duty Paid )</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-3">
                            <a href="#"><i class="bi-postcard"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>DAT</h3>
                            <p class="my-0">( Delivered at Terminal )</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 align-items-stretch ">
                    <div class="feature-box fbox-center fbox-plain px-4 py-5 rounded-3 bg-light border mx-0">
                        <div class="fbox-icon mb-0">
                            <a href="#"><i class="bi-postcard"></i></a>
                        </div>
                        <div class="fbox-content">
                            <h3>Découvre l'Incoterm le mieux adapté à ton projet d'importation</h3>

                        </div>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-12 text-center mt-5">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <div class="section-map mb-6 mt-6" style="background: url('assets/images/ebooks/ebook05/map.png') no-repeat center center; background-size: cover;">
    <div class="container">
        <img src="assets/images/ebooks/ebook05/map.png" alt="Map Image" class="img-fluid map-image">
        <div class="text-center mb-lg-6">
            <h2 class="display-4 fw-bolder">Optimise tes coûts logistiques : Chaque euro compte !
            </h2>
            <h4 class="fw-normal">Optimise tes coûts logistiques : Chaque euro compte !
            </h4>
        </div>
        <div class="map-title">
            <h2 class="text-center">More than 21 Countries included in Our Network. Some of Our Most Popular Countries</h2>
            <div class="d-flex justify-content-center">
                <ul class="iconlist m-0 pe-5">
                    <li><img src="demos/hosting/images/flags/uk.png" alt="Country Flag"><a href="#">United Kingdom</a></li>
                    <li><img src="demos/hosting/images/flags/us.png" alt="Country Flag"><a href="#">USA</a></li>
                    <li><img src="demos/hosting/images/flags/br.png" alt="Country Flag"><a href="#">Brazil</a></li>
                    <li><img src="demos/hosting/images/flags/sa.png" alt="Country Flag"><a href="#">South Africa</a></li>
                    <li><img src="demos/hosting/images/flags/in.png" alt="Country Flag"><a href="#">India</a></li>
                </ul>
                <ul class="iconlist m-0 ps-5">
                    <li><img src="demos/hosting/images/flags/si.png" alt="Country Flag"><a href="#">Singapore</a></li>
                    <li><img src="demos/hosting/images/flags/ja.png" alt="Country Flag"><a href="#">Japan</a></li>
                    <li><img src="demos/hosting/images/flags/au.png" alt="Country Flag"><a href="#">Australia</a></li>
                    <li><img src="demos/hosting/images/flags/ca.png" alt="Country Flag"><a href="#">Canada</a></li>
                    <li><a href="#" class="text-black-50">See all..</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="counter-section">
        <div class="row align-items-stretch m-0 w-100 text-center">

            <div class="col-sm-3 col-6">
                <div class="counter-dots"></div>
                <div class="counter font-secondary"><span data-from="100" data-to="11000" data-refresh-interval="50" data-speed="2100" data-comma="true"></span>+</div>
                <h5 class="text-capitalize ls-0 mt-0"><u>Moved Monthly</u></h5>
            </div>

            <div class="col-sm-3 col-6">
                <div class="counter-dots"></div>
                <div class="counter font-secondary"><span data-from="3" data-to="26" data-refresh-interval="50" data-speed="2200" data-comma="true"></span>+</div>
                <h5 class="text-capitalize ls-0 mt-0"><u>Years of Experience</u></h5>
            </div>

            <div class="col-sm-3 col-6">
                <div class="counter-dots"></div>
                <div class="counter font-secondary"><span data-from="100" data-to="6000" data-refresh-interval="25" data-speed="2300" data-comma="true"></span>+</div>
                <h5 class="text-capitalize ls-0 mt-0"><u>Trained Manpower</u></h5>
            </div>

            <div class="col-sm-3 col-6">
                <div class="counter-dots"></div>
                <div class="counter font-secondary"><span data-from="1" data-to="21" data-refresh-interval="10" data-speed="2100" data-comma="true"></span>+</div>
                <h5 class="text-capitalize ls-0 mt-0"><u>Countries Worldwide</u></h5>
            </div>

        </div>
    </div>
</div> -->


<section style="
background: url('assets/images/ebooks/ebook05/map.png') no-repeat center center;
background-size: cover;
background-position: center;
background-attachment: fixed;
">
    <div class="content-wrap">
        <div class="container">
            <div class="col-md-12 text-center">
                <h2 class="display-4 fw-bolder">Optimise tes coûts logistiques : Chaque euro compte !</h2>
                <p class="text-larger text-muted">Best-Selling &amp; Most Trusted HTML5 Template. Experience the
                    Ever-Growing Feature Rich Template since 2014.</p>
            </div>
            <div class="d-grid col-mb-50">

                <div class="grid-col shadow-sm grid-w  p-5"
                    style="background-color: #f0e80ea1 !important; border-radius: 30px;">
                    <div class="feature-box fbox-sm fbox-plain flex-column">
                        <!-- <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary bi-display"></i></a>
                        </div> -->
                        <h2 class="mb-3 text-black fw-bolder">Fais le bon choix entre transport maritime, aérien ou
                            ferroviaire.</h2>
                        <!-- <div class="fbox-content px-0 mb-4">
                            <h3 class="display-3">Responsive Layout</h3>
                            <p>Powerful Layout with Responsive functionality that can be adapted to any screen size.</p>
                        </div>                        -->
                    </div>
                </div>

                <div class="grid-col shadow-sm rounded p-5">
                    <div class="feature-box flex-column fbox-sm fbox-plain">
                        <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary bi-eye"></i></a>
                        </div>
                        <h2 class="mb-3 text-black fw-bolder">Fais le bon choix entre transport maritime, aérien ou
                            ferroviaire.</h2>
                        <!-- <div class="fbox-content px-0">
                            <h3>Retina Ready Graphics</h3>
                            <p>Looks beautiful &amp; ultra-sharp on Retina Displays with Retina Icons, Fonts &amp;
                                Images.</p>
                        </div> -->
                    </div>
                </div>

                <div class="grid-col shadow-sm bg-light rounded p-5">
                    <div class="feature-box flex-column fbox-sm fbox-plain">
                        <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary bi-star"></i></a>
                        </div>
                        <h2 class="mb-3 text-black fw-bolder">Évite les pièges fréquents lors de l'expédition et la
                            réception des marchandises.</h2>
                        <!-- <div class="fbox-content px-0">
                            <h3>Powerful Performance</h3>
                            <p>Optimized code that are completely customizable and deliver fast performance.</p>
                        </div> -->
                    </div>
                </div>

                <div class="grid-col shadow-sm bg-light rounded p-5">
                    <div class="feature-box flex-column fbox-sm fbox-plain">
                        <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary uil uil-play"></i></a>
                        </div>
                        <div class="fbox-content px-0">
                            <h3>HTML5 Video</h3>
                            <p>Canvas provides support for Native HTML5 Videos that can be added to a Background.</p>
                        </div>
                    </div>
                </div>

                <div class="grid-col shadow-sm bg-light rounded p-5">
                    <div class="feature-box flex-column fbox-sm fbox-plain">
                        <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary bi-mouse"></i></a>
                        </div>
                        <div class="fbox-content px-0">
                            <h3>Parallax Support</h3>
                            <p>Display your Content attractively using Parallax Sections that have unlimited
                                customizable areas.</p>
                        </div>
                    </div>
                </div>

                <div class="grid-col shadow-sm bg-light rounded p-5">
                    <div class="feature-box flex-column fbox-sm fbox-plain">
                        <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary bi-check-circle-fill"></i></a>
                        </div>
                        <div class="fbox-content px-0">
                            <h3>Endless Possibilities</h3>
                            <p>Complete control on each &amp; every element that provides endless customization
                                possibilities.</p>
                        </div>
                    </div>
                </div>

                <div class="grid-col shadow-sm bg-light rounded p-5">
                    <div class="feature-box flex-column fbox-sm fbox-plain">
                        <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary bi-lightbulb"></i></a>
                        </div>
                        <div class="fbox-content px-0">
                            <h3>Light &amp; Dark Color Schemes</h3>
                            <p>Change your Website's Primary Scheme instantly by simply adding the dark class on body.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="grid-col shadow-sm bg-light rounded p-5">
                    <div class="feature-box flex-column fbox-sm fbox-plain">
                        <div class="fbox-icon mb-4">
                            <a href="#"><i class="text-primary bi-heart"></i></a>
                        </div>
                        <div class="fbox-content px-0">
                            <h3>Boxed &amp; Wide Layouts</h3>
                            <p>Stretch your Website to the Full Width or make it boxed to surprise your visitors.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center mt-5 pt-5">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill "></i>OBTENIR LE GUIDE</a>
            </div>
        </div>
    </div>
</section>


<section>
    <div class="container">
        <div class="text-center mb-lg-6">
            <h2 class="display-4 fw-bolder">Sed ut perspiciatis unde omnis iste natus error
            </h2>
            <h4 class="fw-normal">Sed ut perspiciatis unde omnis iste natus error
            </h4>
        </div>
        <div class="row justify-content-center col-mb-50 align-items-stretch">
            <img src="assets/images/ebooks/ebook05/content.png">
        </div>
    </div>
</section>


<div id="section-pricing" class="section my-0 bg-transparent">
    <div class="container py-lg-5">
        <div class="text-center mb-lg-6">
            <h2 class="display-4 fw-bolder">Obtiens dès maintenant ton guide complet pour réussir dans l'import-export !
            </h2>
            <h4 class="fw-normal">Le guide ultime pour maîtriser l'import-export et transformer ton projet en succès.
            </h4>
        </div>
        <div class="clear mb-4"></div>
        <div class="row justify-content-center gy-5" style="--bs-gutter-x: 60px">
            <div class="col-xl-6 col-lg-6">
                <div class="card border-0  pt-0" style="background-color: #FFD600; border-radius: 20px;">
                    <div class="card-body text-center p-5 pt-0">
                        <i class="bi-person h2 m-0 bg-white rounded-circle icon-stacked text-center"
                            style="transform: translateY(-32px);"></i>
                        <h2 class="fw-bolder fs-2 mb-0">Guide Import-Export</h2>
                        <p class="">La feuille de route la plus complète pour réussir dans l'import-export</p>
                        <div class="">
                            <h4 style="color: #000000; font-size: 27px;" class="mb-0 strike">97.99€</h4>
                            <!-- <hr> -->
                            <h2 class="mb-0 display-1 fw-bolder">27€</h2>
                            <!-- <div class="ms-3">
                                <div class="mb-0 h6 fw-semibold lh-base">Pai</div>
                                <small class="mb-0 op-08 lh-base">with Lifetime free Updates</small>
                            </div> -->
                        </div>
                        <div class="small op-07 mt-2">Paiement unique</div>

                        <div class="fw-bolder pt-3">Obtenez un guide complet qui couvre chaque étape de votre activité
                            d'import-export :</div>

                        <div
                            class="text-start my-5 d-flex flex-column justify-content-center align-items-center text-start">
                            <div>
                                <h4 class="mb-3 h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut
                                    perspiciatis unde omnis</h4>
                                <h4 class="mb-3 h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut
                                    perspiciatis unde omnis</h4>
                                <h4 class="mb-3 h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut
                                    perspiciatis unde omnis</h4>
                                <h4 class="h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut perspiciatis
                                    unde omnis</h4>
                            </div>
                        </div>
                        <a href="#"
                            class="button button-xlarge bg-white text-dark px-5 py-3 w-100 rounded-pill h-op-09 m-0 fw-bolder"
                            style=" white-space: normal;">Achetez mon guide import-export dès maintenant !</a>
                        <div style="font-size: 12px;" class="mt-2 pt-2">Vous serez redirigé vers un paiement sécurisé où
                            vous pourrez payer avec :</div>
                        <img src="assets/images/ebooks/ebook05/card.png">
                        <div style="font-size: 12px;" class="mt-2">Après paiement, vous recevrez votre tableau
                            immédiatement.</div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6">
                <div class="card border-0 pt-0" style="background-color: #dfdfdf; border-radius: 20px;">
                    <div class="card-body text-center p-5 pt-0">
                        <i class="bi-person h2 m-0 bg-white rounded-circle icon-stacked text-center"
                            style="transform: translateY(-32px);"></i>
                        <h2 class="fw-bolder fs-2 mb-0">Guide Import-Export + Appel de 45 minutes</h2>
                        <p class="">La feuille de route la plus complète pour réussir dans l'import-export</p>
                        <div class="">
                            <h4 style="color: #000000; font-size: 27px;" class="mb-0 strike">997.99€</h4>
                            <!-- <hr> -->
                            <h2 class="mb-0 display-1 fw-bolder">297€</h2>
                            <!-- <div class="ms-3">
                                <div class="mb-0 h6 fw-semibold lh-base">Pai</div>
                                <small class="mb-0 op-08 lh-base">with Lifetime free Updates</small>
                            </div> -->
                        </div>
                        <div class="small op-07 mt-2">Paiement unique</div>

                        <div class="fw-bolder pt-3">Obtenez un guide complet qui couvre chaque étape de votre activité
                            d'import-export :</div>

                        <div
                            class="text-start my-5 d-flex flex-column justify-content-center align-items-center text-start">
                            <div>
                                <h4 class="mb-3 h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut
                                    perspiciatis unde omnis</h4>
                                <h4 class="mb-3 h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut
                                    perspiciatis unde omnis</h4>
                                <h4 class="mb-3 h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut
                                    perspiciatis unde omnis</h4>
                                <h4 class="h6 fw-medium"><i class="bi-check-circle-fill me-2"></i> Sed ut perspiciatis
                                    unde omnis</h4>
                            </div>
                        </div>
                        <a href="#"
                            class="button button-xlarge bg-white text-dark px-5 py-3 w-100 rounded-pill h-op-09 m-0 fw-bolder"
                            style=" white-space: normal;">Achetez mon guide import-export dès maintenant !</a>
                        <div style="font-size: 12px;" class="mt-2 pt-2">Vous serez redirigé vers un paiement sécurisé où
                            vous pourrez payer avec :</div>
                        <img src="assets/images/ebooks/ebook05/card.png">
                        <div style="font-size: 12px;" class="mt-2">Après paiement, vous recevrez votre tableau
                            immédiatement.</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 mt-lg-6">
                <h2 class="mb-5 display-6 fw-bolder">Tu as encore des questions ?</h2>
                <div class="px-lg-6 p-5 rounded"
                    style="background: linear-gradient( to bottom left, rgba(255, 224, 54, .15), rgba(216, 220, 232, .3) 70% );">
                    <div class="toggle py-4 border-bottom mb-0" style="--cnvs-toggle-font-size: 1.125rem;">
                        <div class="toggle-header align-items-center">
                            <div class="toggle-title fw-semibold">
                                How will I get my Project after Subscribed?
                            </div>
                            <div class="toggle-icon">
                                <i class="toggle-closed bi-plus-circle fs-4 op-05"></i>
                                <i class="toggle-open bi-dash-circle fs-4"></i>
                            </div>
                        </div>
                        <div class="toggle-content fs-6" style="display: none;">Competently myocardinate sticky
                            technology through competitive testing procedures. Progressively engineer customer directed
                            e-services before frictionless scenarios. Objectively strategize next-generation web
                            services for professional catalysts for change. Quickly productize clicks-and-mortar schemas
                            for collaborative e-business. Dynamically customize user friendly leadership skills and
                            interactive e-services. Objectively actualize virtual imperatives vis-a-vis enabled.</div>
                    </div>

                    <div class="toggle py-4 border-bottom mb-0" style="--cnvs-toggle-font-size: 1.125rem;">
                        <div class="toggle-header align-items-center">
                            <div class="toggle-title fw-semibold">
                                Can I cancel anytime my Subscription?
                            </div>
                            <div class="toggle-icon">
                                <i class="toggle-closed bi-plus-circle fs-4 op-05"></i>
                                <i class="toggle-open bi-dash-circle fs-4"></i>
                            </div>
                        </div>
                        <div class="toggle-content fs-6" style="display: none;">Competently myocardinate sticky
                            technology through competitive testing procedures. Progressively engineer customer directed
                            e-services before frictionless scenarios. Objectively strategize next-generation web
                            services for professional catalysts for change. Quickly productize clicks-and-mortar schemas
                            for collaborative e-business. Dynamically customize user friendly leadership skills and
                            interactive e-services. Objectively actualize virtual imperatives vis-a-vis
                            enabled.<br><br>Voluptates doloribus quae nisi tempore necessitatibus dolores ducimus enim
                            libero eaque explicabo suscipit animi at quaerat aliquid ex expedita perspiciatis? Saepe,
                            aperiam, nam unde quas beatae vero vitae nulla.</div>
                    </div>

                    <div class="toggle py-4 border-bottom mb-0" style="--cnvs-toggle-font-size: 1.125rem;">
                        <div class="toggle-header align-items-center">
                            <div class="toggle-title fw-semibold">
                                How does Business Plan work?
                            </div>
                            <div class="toggle-icon">
                                <i class="toggle-closed bi-plus-circle fs-4 op-05"></i>
                                <i class="toggle-open bi-dash-circle fs-4"></i>
                            </div>
                        </div>
                        <div class="toggle-content fs-6" style="display: none;">Lorem ipsum dolor sit amet, consectetur
                            adipisicing elit. Assumenda, dolorum, vero ipsum molestiae minima odio quo voluptate illum
                            excepturi quam cum voluptates doloribus quae nisi tempore necessitatibus dolores ducimus
                            enim libero eaque explicabo suscipit animi at quaerat aliquid ex expedita perspiciatis?
                            Saepe, aperiam, nam unde quas beatae vero vitae nulla.</div>
                    </div>

                    <div class="toggle py-4 mb-0" style="--cnvs-toggle-font-size: 1.125rem;">
                        <div class="toggle-header align-items-center">
                            <div class="toggle-title fw-semibold">
                                Is there any hidden charges?
                            </div>
                            <div class="toggle-icon">
                                <i class="toggle-closed bi-plus-circle fs-4 op-05"></i>
                                <i class="toggle-open bi-dash-circle fs-4"></i>
                            </div>
                        </div>
                        <div class="toggle-content fs-6" style="display: none;">Compellingly morph virtual niche markets
                            vis-a-vis excellent paradigms. Assertively orchestrate quality deliverables vis-a-vis
                            cross-unit e-commerce. Proactively deploy fully tested paradigms for cross-media content.
                            Conveniently implement cross-media processes whereas highly efficient opportunities.
                            Objectively scale flexible partnerships vis-a-vis end-to-end meta-services.
                            <br><br>Conveniently synergize front-end content rather than high-payoff users.
                            Professionally parallel.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-simulator-footer></app-simulator-footer>