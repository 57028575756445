import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class Ebook05Session {

    private ebook05 = {}

    constructor() {
    }

    createObjectEbook05() {
        let ebook05 = {
            theme: {                
                  backgroundColor: '#ffd600',
                  borderColor: '2px solid #ffd600',
                  textColor: 'white',
                  themeTextColor: '#2C3E50'
                                
              },
            budget : 500,
            budgetCheck : false,           
            step1: {
                title : 'Tu sais ce que tu veux importer ?',
                response : '',
                title_response: '',
                check: false,
                responses: {
                    response01: {
                        title : 'Oui',
                        saleText : ''
                    },
                    response02: {
                        title : 'Non',
                        saleText : ''
                    }
                }
            },
            step3: {
                title : 'Tu à déjà créer ta Société ?',
                response : '',
                title_response: '',
                check: false,
                responses: {
                    response01: {
                        title : 'Oui',
                        saleText : ''
                    },
                    response02: {
                        title : 'Non',
                        saleText : ''
                    }
                }
            }
        }
        return ebook05;
    }

    // /**
    //  * Sécurise le texte HTML en utilisant DomSanitizer.
    //  * @param text Le texte à sécuriser.
    //  * @returns Le texte sécurisé sous forme de SafeHtml.
    //  */
    // sanitize(text: string): SafeHtml {

    //     const rawTitle = 'Non, je cherche encore<br> l\'inspiration';

    //     let a = this.sanitizer.bypassSecurityTrustHtml(rawTitle);

    //     console.log(a); // Vérification du texte sécurisé

    //     return this.sanitizer.bypassSecurityTrustHtml(text);
    // }

    /**
   * Initialise la session de simulation en fonction du type spécifié.
   * @param type Le type de simulateur à initialiser (par exemple, 'simulateur-bu-01').
   */
    initializeSimulator(type: string): void {
        if (type === 'guide-import-export-ebook-05') {
            this.ebook05 = this.createObjectEbook05();
        }
    }

    /**
       * Charge l'état du simulateur depuis le sessionStorage si disponible.
       * @param idSession L'ID de la session à charger.
       */
    loadState(idSession: any): void {
        const savedState = this.getItem(idSession);
        if (savedState) {
            this.ebook05 = savedState;
        }
    }

    /**
       * Sauvegarde l'état actuel du simulateur dans le sessionStorage.
       * @param idSession L'ID de la session à sauvegarder.
       */
    saveState(idSession: any): void {
        this.setItem(idSession, this.ebook05);

    }

    /**
   * Enregistre un élément dans le sessionStorage.
   * @param key La clé sous laquelle enregistrer l'élément.
   * @param value La valeur à enregistrer.
   */
    setItem(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    /**
    * Récupère un élément depuis le sessionStorage.
    * @param key La clé de l'élément à récupérer.
    * @returns L'élément récupéré ou null s'il n'existe pas.
    */
    getItem(key: string): any {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    /**
    * Récupère un élément depuis le sessionStorage.
    * @param key La clé de l'élément à récupérer.
    * @returns L'élément récupéré ou null s'il n'existe pas.
    */
    removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }

    /**
     * Vide complètement le sessionStorage.
     */
    clearSession(): void {
        sessionStorage.clear();
    }

}