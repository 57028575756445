<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>


<section id="slider" class="slider-element  scroll-detect include-header bg-contrast-100 scroll-detect-inview "
    >
    <div class="py-3 mt-xl-6 position-relative z-2">
        <div class="container">
            <div class="row align-items-center justify-content-center section-video-vsl">
                <div class="col-12 text-center pt-0 pb-0">
                    <h1 class="mb-4 display-4 pt-6">
                        <span class="mb-4 fw-bolder text-black">S’aimer : Le Guide Ultime pour Retrouver Confiance et
                            Sérénité</span>
                    </h1>
                    <p>
                        Découvre le chemin vers une version plus forte, épanouie et authentique de toi-même. Avec
                        “S’aimer”, il est temps de te reconnecter avec la personne extraordinaire que tu es déjà.
                    </p>
                    <div class="wistia_responsive_padding mb-5" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_zmlkvt8b1i seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/zmlkvt8b1i/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10 center mb-4">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>



    <div class="position-absolute w-100 h-100 top-0 start-0 z-1 py-0">
        <div class="d-flex px-4 w-100 mt-6 pt-5">
            <div class="position-relative w-50 intro-hero-images-section">
                <div class="position-relative d-flex fadeInDownSmall animated" style="width:35%;left:1%;"
                    data-animate="fadeInDownSmall">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Classic Demo"
                        class="rounded lazy w-50 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute fadeInRightSmall animated" style="width:65%;right:-14.5%;top:-50%;"
                        data-animate="fadeInRightSmall" data-delay="550">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Speaker Demo"
                            class="rounded lazy ms-4 shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInLeft animated" style="width:37%;left:-18%;"
                    data-animate="fadeInLeft" data-delay="250">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Recipes Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute zoomIn animated" style="width: 50%;right: -57%;top: -9%;"
                        data-animate="zoomIn" data-delay="800">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                            data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="App Landing Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(var(--cnvs-scroll-end)*30%),calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                    <div class="position-absolute zoomIn animated" style="width: 48%;right: -55%;top: 51.5%;"
                        data-animate="zoomIn" data-delay="1000">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Yoga Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(var(--cnvs-scroll-end)*50%),calc(var(--cnvs-scroll-end)*200%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInUp animated" style="width:38%;left: 3%;"
                    data-animate="fadeInUp" data-delay="500">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                        data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="Supplements Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                </div>
            </div>
            <div class="position-relative w-50 intro-hero-images-section d-flex flex-column align-items-end">
                <div class="position-relative d-flex fadeInDownSmall animated" style="width:35%;right:1%;"
                    data-animate="fadeInDownSmall">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Conference Demo"
                        class="rounded lazy w-50 shadow ms-auto entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute fadeInLeftSmall animated" style="width: 65%;left:-30%;top:-50%;"
                        data-animate="fadeInLeftSmall" data-delay="550">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                            data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="Freelancer Demo"
                            class="rounded lazy shadow ms-4 w-100 entered lazy-loaded"
                            style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInRight animated" style="width:37%;right:-18%;"
                    data-animate="fadeInRight" data-delay="250">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="images/intro/niche/new/cleaner.jpg" alt="Cleaner Demo"
                        class="rounded lazy w-100 shadow ms-auto entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute zoomIn animated" style="width: 50%;left: -57%;top: -9%;"
                        data-animate="zoomIn" data-delay="800">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Furniture Demo"
                            class="rounded lazy w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(-1*var(--cnvs-scroll-end)*30%),calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                    <div class="position-absolute zoomIn animated" style="width: 48%;left: -55%;top: 51.5%;"
                        data-animate="zoomIn" data-delay="1000">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Skincare Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(-1*var(--cnvs-scroll-end)*50%),calc(var(--cnvs-scroll-end)*200%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInUp animated" style="width:38%;right: 3%;"
                    data-animate="fadeInUp" data-delay="500">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                        data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="SEO Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                </div>
            </div>
        </div>
    </div>


</section>

<section>
    <div class="container">
        <div class="text-center section-mockup-title">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <h6 class="display-5 fw-bolder ls1 mb-5 position-relative d-none d-md-block text-black">
                Pourquoi ce guide est pour toi ?
            </h6>
            <!-- Contenu visible uniquement sur les smartphones -->
            <h6 class="display-6 fw-bolder ls1 mb-5 position-relative d-block d-md-none text-black">
                Pourquoi ce guide est pour toi ?
            </h6>
            <p class="lead">
                As-tu déjà ressenti un vide, une frustration ou un doute constant sur ta valeur ? Dans un monde qui
                valorise l’apparence et la productivité, il est facile de se perdre. Mais la vraie transformation ne
                commence pas à l’extérieur, elle vient de l’intérieur.
            </p>
        </div>


        <div class="row ">
            <div class="col-xl-12 col-lg-12 pt-0 center">
                <h2 class="weight-w8 display-7 mb-3">S’aimer, c’est ton invitation à :</h2>
            </div>
            <div class="col-6">
                <div class="border  p-4 mb-4 h-shadow all-ts  h-translatey-sm" style="background-color: #F4F1EC;">
                    <div class="row col-mb-30">
                        <div class="col-lg-12 col-md-12">
                            <h2 style="font-size: 20px;" class="display-7 text-black mb-2 fw-bolder">Guérir tes
                                blessures invisibles.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="border rounded p-4 mb-4 h-shadow all-ts" style="background-color: #B09079;">
                    <div class="row align-items-center col-mb-30">
                        <div class="col-lg-12 col-md-12">
                            <h2 style="font-size: 20px;" class="display-7 text-white mb-2 fw-bolder">Apprendre à t’aimer
                                sans conditions.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="border rounded p-4 mb-4 h-shadow all-ts h-translatey-sm" style="background-color: #B09079;">
                    <div class="row align-items-center col-mb-30">
                        <div class="col-lg-12 col-md-12">
                            <h2 style="font-size: 20px;" class="text-white mb-2 fw-bolder">Bâtir une estime de soi
                                solide et durable.</h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="row justify-content-center">
            <div class="col-10 center">
                <img src="assets/images/ebooks/ebook01/1.jpg" width="600px">

            </div>
            <div class="col-10 center pt-5">
                <a (click)="scrollToElement('section-pricing')"
                    class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
            </div>
        </div> -->
    </div>
</section>



<section class="mockup-section">
    <div class="content-wrap">
        <div class="container">
            <div class="text-center mb-3">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-6 position-relative d-none d-md-block">
                    105 pages pour aller mieux !
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-0 position-relative d-block d-md-none">
                    105 pages pour aller mieux !
                </h6>
            </div>
            <div class="row gallery-categories gutter-20 mockup-section-gallery">
                <div class="col-md-12">
                    <div
                        style="background: url('assets/images/ebooks/ebook01/mock3.jpeg') no-repeat center center; background-size: cover; height: 450px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook01/mock1.jpeg') no-repeat center center; background-size: cover; height: 250px;">
                    </div>
                </div>

                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook01/mock2.jpeg') no-repeat 50% 40%; background-size: cover; height: 250px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook01/mock4.jpeg') no-repeat 100% 100%; background-size: cover; height: 250px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section-programme">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-programme-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Le pouvoir transformateur de l’amour propre
                        <br />Un voyage captivant à travers des chapitres essentiels
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Le pouvoir transformateur de l’amour propre:
                        <br />Un voyage captivant à travers des chapitres essentiels
                    </h6>
                </div>
                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #B09079;"
                            data-bs-theme="dark">
                            <h3 class="mb-3 display-6 fw-bold"><b>Une newsletter mensuelle inspirante et motivante à
                                    vie</b></h3>
                            <img src="assets/images/ebooks/ebook01/aura-logo.png" width="180px">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <h3 class="mb-3 display-7 fw-bold"><b>Trouver le Soi</b></h3>
                            <p class="lead mb-0">Apprends à reconnaître et à écouter ton essence profonde en dépassant
                                les croyances et comportements qui ne te servent plus, pour te reconnecter à la personne
                                extraordinaire que tu es réellement. Ce chapitre pose les bases d’une transformation
                                intérieure authentique.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/presentiel.png" data-animate="tada slower infinite" width="100px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Amour de Soi et l’Ombre Intérieure </b></h3>
                            <p class="lead mb-0">Découvre comment embrasser tes parts d’ombre et travailler avec elles
                                pour les intégrer dans un tout harmonieux. Comprends pourquoi cette réconciliation est
                                cruciale pour libérer ton potentiel et t’aimer inconditionnellement, sans honte ni
                                culpabilité.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Développer l’Amour de Soi</b>
                            </h3>
                            <p class="lead mb-0">Explore des exercices pratiques et des techniques inspirantes pour
                                bâtir une relation saine et solide avec toi-même. Tu apprendras à cultiver l’estime de
                                toi au quotidien et à faire de l’amour de soi un pilier inébranlable de ta vie.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg " data-bs-theme="dark">
                            <h3 class="mb-3 display-7 fw-bold"><b>Stratégies Alternatives</b></h3>
                            <p class="lead">Libère-toi des schémas mentaux et des croyances limitantes héritées de ton
                                passé et de la société. Découvre des approches inédites et créatives pour déconstruire
                                ces barrières et te reconnecter à une version libre et authentique de toi-même.
                            </p>
                            <!-- <img src="images/replay.png" data-animate="tada slower infinite" width="100px" class="center tada slower infinite animated" alt="Feature Icon"> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #F4F1EC;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold text-black"><b>La Complexité et la Psychologie de l’Estime
                                    de Soi</b>
                            </h3>
                            <p class="lead text-black">Décode les mécanismes souvent invisibles qui influencent ta
                                perception de toi-même et apprends comment les surmonter. Ce chapitre t’offre une
                                compréhension approfondie pour reconstruire une estime de soi stable et durable.
                            </p>
                        </div>
                    </div>

                </div>
                <div class="row justify-content col-mb-50 align-items-stretch pt-5">


                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #F4F1EC;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Maîtriser les Pensées et les Émotions</b>
                            </h3>
                            <p class="lead mb-0">Comprends comment tes pensées façonnent tes émotions et comment, en
                                changeant ton prisme mental, tu peux transformer ta réalité. Tu apprendras à
                                reconnaître, accueillir et maîtriser tes émotions pour en faire des alliées précieuses
                                dans ton parcours de vie.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Des Moyens Créatifs pour Renforcer l’Amour de Soi</b>
                            </h3>
                            <p class="lead mb-0">Découvre comment libérer ta créativité pour nourrir ton amour-propre de
                                manière ludique et unique. Ce chapitre te guidera dans des pratiques inspirantes comme
                                l’art, l’écriture ou même l’exploration de nouvelles passions, pour retrouver un élan de
                                joie et d’épanouissement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="py-5 text-center bg-contrast-100 border-top border-bottom border-contrast-200"
        data-class="dark:bg-contrast-200 dark:border-contrast-300">
        <div class="container">
            <img src="assets/images/ebooks/ebook01/aura-logo.png" width="180px">
            <h2 class="mb-0"><strong class="text-black">Une newsletter mensuelle inspirante et motivante : </strong>
                <!-- <em class="fw-bold">Lifetime Free</em> for New Demos, Features, Fixes &amp; Updates. -->
            </h2>
            <p>En t’inscrivant, tu recevras chaque mois des réflexions, des conseils pratiques et des encouragements
                pour continuer à nourrir ton amour-propre et ta sérénité. C’est ton rendez-vous pour maintenir la flamme
                de la transformation allumée.</p>
            <div class="pt-3">
                <a (click)="scrollToElement('section-pricing')"
                    class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
            </div>
        </div>
    </div>
</section>


<section class="section-programme-book-workbook">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-programme-book-workbook-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Des outils exclusifs pour transformer ton quotidien :
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Des outils exclusifs pour transformer ton quotidien :
                    </h6>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<!-- Journal de l’Amour-Propre et Journal de Pleine Conscience -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Journal de l’Amour-Propre et Journal de Pleine Conscience</h2>
                    <p class="lead mb-5">Ces journaux te guideront dans une réflexion quotidienne et hebdomadaire pour
                        cultiver la gratitude, identifier tes forces et reconnaître les petits pas vers une meilleure
                        version de toi-même.</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/journal-de-l-amour-propre.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/journal-de-l-amour-propre-img.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<!-- Journal de l’Amour-Propre et Journal de Pleine Conscience -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Journal de l’Amour-Propre et Journal de Pleine Conscience</h2>
                    <p class="lead mb-5">Ces journaux te guideront dans une réflexion quotidienne et hebdomadaire pour
                        cultiver la gratitude, identifier tes forces et reconnaître les petits pas vers une meilleure
                        version de toi-même.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/journal-de-l-amour-propre-phone.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <!-- <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/journalamour-2.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>                            
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<!-- Suivi du Sommeil et de l’Humeur : -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-5">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/suivi-du-sommeil-et-de-l-humeur.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/suivi-du-sommeil-et-de-l-humeur-img.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Suivi du Sommeil et de l’Humeur</h2>
                    <p class="lead mb-5">Une méthode simple et efficace pour observer tes cycles de sommeil, noter tes
                        émotions et mieux comprendre ce qui influence ton bien-être au jour le jour.</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<!-- Suivi du Sommeil et de l’Humeur : -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Suivi du Sommeil et de l’Humeur</h2>
                    <p class="lead mb-0">Une méthode simple et efficace pour observer tes cycles de sommeil, noter tes
                        émotions et mieux comprendre ce qui influence ton bien-être au jour le jour.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/suivi-du-sommeil-et-de-l-humeur-phone.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <!-- <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>                        
                        </div> -->

                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<!-- Planificateur de Soins Personnels et Routine -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Planificateur de Soins Personnels et Routine</h2>
                    <p class="lead mb-5">Un outil pratique pour organiser des moments de détente, te ressourcer et
                        intégrer des habitudes qui soutiennent ton équilibre mental, physique et émotionnel.</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/planificateur-de-soins-personnels-et-routine.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/planificateur-de-soins-personnels-et-routine-img.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<!-- Planificateur de Soins Personnels et Routine -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Planificateur de Soins Personnels et Routine</h2>
                    <p class="lead mb-0">Un outil pratique pour organiser des moments de détente, te ressourcer et
                        intégrer des habitudes qui soutiennent ton équilibre mental, physique et émotionnel.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/planificateur-de-soins-personnels-et-routine-phone.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <!-- <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>                            
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<!-- Journal de l’Eau -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/journal-de-l-eau.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/journal-de-l-eau-img.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Journal de l’Eau </h2>
                    <p class="lead mb-5">Transforme un geste simple en rituel bénéfique en suivant ton hydratation et en
                        t’assurant que ton corps reçoit les soins qu’il mérite chaque jour.</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<!--  Journal de l’Eau -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Journal de l’Eau </h2>
                    <p class="lead mb-0">Transforme un geste simple en rituel bénéfique en suivant ton hydratation et en
                        t’assurant que ton corps reçoit les soins qu’il mérite chaque jour.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/journal-de-l-eau-phone.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <!-- <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>                          
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<!--  Liste “Défaire l’Indéfait”  -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Liste “Défaire l’Indéfait”</h2>
                    <p class="lead mb-5">Une liste unique qui t’invite à te libérer des habitudes, pensées et
                        comportements inutiles, pour créer de l’espace pour ce qui compte vraiment.</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/liste-defaire-l-indefait.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/liste-defaire-l-indefait-img.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<!-- Liste “Défaire l’Indéfait” -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Liste “Défaire l’Indéfait”</h2>
                    <p class="lead mb-0">Une liste unique qui t’invite à te libérer des habitudes, pensées et
                        comportements inutiles, pour créer de l’espace pour ce qui compte vraiment.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/liste-defaire-l-indefait-phone.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <!-- <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>                            
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<!-- Exercice pour Se Vider la Tête -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/exercice-pour-se-vider-la-tete.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook01/exercice-pour-se-vider-la-tete-img.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Exercice pour Se Vider la Tête</h2>
                    <p class="lead mb-5">Une activité puissante pour évacuer le stress et clarifier tes pensées en
                        couchant sur papier tout ce qui te traverse l’esprit, afin de retrouver calme et focus</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->.
<!-- Exercice pour Se Vider la Tête -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Workbook</div>
                    <h2 class="display-4 mb-4 fw-bolder">Exercice pour Se Vider la Tête</h2>
                    <p class="lead mb-0">Une activité puissante pour évacuer le stress et clarifier tes pensées en
                        couchant sur papier tout ce qui te traverse l’esprit, afin de retrouver calme et focus</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook01/exercice-pour-se-vider-la-tete-phone.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <!-- <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>                            
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Desktop version -->
<section id="section-pricing" class="section-price">
    <div class="content-wrap">
        <div class="container">

            <div class="row justify-content-between">

                <div class="col-xl-4 mb-5 mb-xl-0">
                    <div class="row mx-0 h-100 rounded-5 p-5" style="background-color: #B09079">
                        <div class="col-12 col-lg-8 col-xl-12">
                            <h3 class="h1 mb-4">Prête à t’aimer pleinement ?</h3>
                            <p>Ne remets pas ton bien-être à demain. Transforme ta vie, une page à la fois.</p>
                        </div>
                        <img src="assets/images/ebooks/ebook01/prix.jpg" alt="Image"
                            class="mt-xl-auto col-12 col-lg-4 col-xl-12">
                    </div>
                </div>

                <div class="col-xl-8 d-flex flex-column justify-content-end">
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <h3 class="h1">Rejoins la communauté des femmes qui transforment leur vie.</h3>
                        </div>

                    </div>
                    <div class="row pricing">
                        <div class="col-md-6">
                            <div
                                class="pricing-box rounded-5 pricing-simple p-5 bg-light border text-center text-md-start">
                                <div class="d-flex flex-column flex-lg-row justify-content-between mb-4">
                                    <div>
                                        <h3 class="mb-0 h1" style="line-height: 1">12€</h3>
                                        <small>Accès immédiat / Paiement unique</small>
                                        <hr>
                                        <h3 class="mb-3">Le guide “S’aimer” + Le Workbook<span
                                                style="color: #F8F9FA;"><br>.</span>
                                        </h3>
                                        <div class="line border-width-4 border-dark p-0 m-0 w-25 mx-auto mx-md-0"></div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <a (click)="checkout('starter')" *ngIf="checkoutButton === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                                    <a *ngIf="checkoutButtonLoading === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><img
                                            src="assets/images/loading.gif" width="20px"></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div
                                class="pricing-box rounded-5 pricing-simple p-5 bg-light border text-center text-md-start">
                                <div class="d-flex flex-column flex-lg-row justify-content-between mb-4">
                                    <div>
                                        <h3 class="mb-0 h1" style="line-height: 1">27€</h3>
                                        <small>Accès immédiat / Paiement unique</small>
                                        <hr>
                                        <h3 class="mb-3">Le guide “S’aimer” + Le Workbook + La Newsletter Mensuelle</h3>
                                        <div class="line border-width-4 border-dark p-0 m-0 w-25 mx-auto mx-md-0"></div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <a (click)="checkout('Premium')" *ngIf="checkoutButton === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                                    <a *ngIf="checkoutButtonLoading === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><img
                                            src="assets/images/loading.gif" width="20px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="section-faq">
    <div class="content-wrap py-0">
        <div id="section-faqs" class="page-section my-5">
            <div class="container">
                <div class="row justify-content-between position-relative">
                    <div class="col-lg-5">
                        <h3 class="display-3 ls-n-3 fw-bolder mb-3">
                            <span style="color: #B09079;">Questions</span>
                            Fréquemment Posées
                        </h3>
                        <img src="assets/images/ebooks/ebook01/prix.jpg" alt="...">
                    </div>
                    <div class="col-lg-6 bg-contrast-0 p-5 rounded-xxl pt-6">
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    À qui s’adresse l’eBook “S’aimer” ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Cet eBook s’adresse à toutes les femmes qui souhaitent cultiver leur amour-propre,
                                guérir leurs blessures intérieures, et retrouver une estime de soi solide. Que tu sois
                                au début de ton cheminement personnel ou déjà en quête d’approfondissement, “S’aimer”
                                est conçu pour t’accompagner à chaque étape.
                            </div>
                        </div>

                        <div class="line border-contrast-300 my-4"></div>

                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que ce guide convient aux débutants ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Absolument ! Le workbook est accessible et guidé pas à pas. Même si tu n’as jamais
                                exploré le développement personnel auparavant, les exercices pratiques et les
                                explications claires te permettront d’avancer à ton rythme.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de temps dois-je consacrer à ce workbook ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Cela dépend entièrement de ton emploi du temps et de ton engagement. Tu peux y consacrer
                                quelques minutes par jour ou approfondir certains chapitres en prenant plus de temps.
                                C’est un guide flexible que tu peux revisiter autant de fois que tu le souhaites.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quels sont les avantages de la newsletter mensuelle incluse dans le Pack Premium ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                La newsletter mensuelle te propose des réflexions inspirantes, des exercices exclusifs
                                et des conseils motivants pour rester alignée avec tes objectifs de bien-être. Elle est
                                conçue comme un rappel régulier pour nourrir ton amour-propre et rester connectée à ton
                                cheminement.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que je reçois un format papier ou numérique ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                L’eBook et les feuilles d’exercices sont disponibles en format numérique, ce qui te
                                permet de les télécharger immédiatement après l’achat. Tu peux les imprimer si tu
                                préfères écrire sur papier ou les remplir directement sur ton appareil.
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>