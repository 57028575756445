<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-12 col-lg-12">
                        <a routerLink="/ebook/guide-import-export/01/produit"
                            class="button  button-rounded fw-bolder"><i class="bi-arrow-left-circle-fill"></i></a>
                        <a routerLink="/ebook/guide-import-export/01/produit"
                            class="button  button-rounded fw-bolder">2.
                            Le produit</a>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 500 à 2000 -->
<!-- réponse oui pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget <= 2000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu sais ce que tu veux importer. Maintenant, tu passes à l’action.
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu sais ce que tu veux importer. Maintenant, tu passes à l’action.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">

                        <p class="mb-0">
                            Avec {{session.budget | currency}} et une idée claire en tête, tu es déjà en train de poser
                            les bases d’un
                            business rentable. Ce budget est suffisant pour démarrer intelligemment, valider ton produit
                            et sécuriser tes premières importations.
                        </p>
                        <hr>
                        <p>
                            tu vas identifier les meilleurs fournisseurs, tu optimises chaque euro
                            investi et tu construis une base solide pour passer au niveau supérieur.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            La Feuille de Route
                        </h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Valider la Qualité</h2>
                            <p class="text-black mb-0 pt-4">
                                Tu contactes des fournisseurs fiables directement en Chine ou via des agents de
                                sourcing.
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">
                                Tu demandes des échantillons pour t’assurer que le produit respecte tes attentes et les
                                normes européennes (CE, RoHS, EN71, etc.).
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Négocie </h2>
                            <p class="text-black mb-0 pt-4">Tu utilises ta connaissance du produit pour négocier
                                des prix compétitifs, même pour de petites quantités.
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">
                                Tu privilégies les Incoterms FOB (Free On Board) pour réduire tes responsabilités
                                logistiques et optimiser tes coûts.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Maximise Chaque Euro</h2>
                            <p class="text-black mb-0 pt-4">Tu testes ton produit en petites quantités sur des
                                marketplaces comme Amazon ou Etsy pour valider la demande.<br>
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">
                                Tu choisis des solutions logistiques économiques, comme le groupage, pour transporter
                                tes marchandises à moindre coût.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Tu n’attends plus. Tu agis maintenant pour transformer ton idée en réalité. Ce budget,
                                utilisé avec une stratégie claire, te permet de poser les premières pierres de ton
                                succès. Chaque étape que tu franchis te rapproche de ton objectif</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Budget de 500 à 2000 -->
<!-- Réponse NON pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '02' && session.budget <= 2000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <!-- En-tête -->
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu as le budget, mais tu ne sais pas encore quoi importer ? Pas de problème, on va clarifier
                        tout ça.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu as le budget, mais tu ne sais pas encore quoi importer ? Pas de problème, on va clarifier
                        tout ça.
                    </h3>
                </div>

                <!-- Texte principal -->
                <div class="row justify-content-center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ne pas savoir quoi importer n’est pas un frein, c’est une opportunité. Avec {{session.budget
                            | currency}},
                            ton objectif est simple : trouver le produit parfait pour démarrer ton business et poser les
                            bases d’une activité rentable.
                        </p>
                        <hr>
                        <p>
                            Le marché regorge d’opportunités, et il suffit de suivre une
                            stratégie claire pour dénicher des idées de produits à fort potentiel.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>
                </div>

                <!-- Étapes -->
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <!-- Étape 1 -->
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-3">Explore les Tendances du Marché</h2>
                            <p class="text-black mb-0">
                                Tu regardes les produits qui explosent sur les marketplaces comme Amazon, Etsy, ou eBay.
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0">
                                Tu identifies des secteurs à forte demande : bien-être, maison, électronique, ou
                                accessoires.
                            </p>
                        </div>
                    </div>

                    <!-- Étape 2 -->
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-3">Trouve un Produit qui Répond à un Besoin</h2>
                            <p class="text-black mb-0">
                                Tu te demandes : "Quels problèmes puis-je résoudre avec mon produit ?"
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0">
                                Tu cibles des produits légers, faciles à transporter et avec une forte valeur perçue.
                            </p>
                        </div>
                    </div>

                    <!-- Étape 3 -->
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-3">Teste les Niches Stratégiques</h2>
                            <p class="text-black mb-0">
                                Tu ne te limites pas à une seule idée : tu testes plusieurs niches avec de petites
                                quantités pour voir ce qui fonctionne.
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0">
                                Tu utilises des outils comme Google Trends ou des plateformes d’analyse pour identifier
                                les opportunités.
                            </p>
                        </div>
                    </div>
                    <!-- Citation -->
                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Ne pas savoir quoi importer, c’est normal. Ce qui compte, c’est que tu passes à l’action.
                                Chaque grande réussite a commencé par une exploration. Maintenant, c’est à toi de
                                construire ton succès, une étape à la fois.</p>
                        </blockquote>
                    </div>

                    <!-- Bouton Étape suivante -->
                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;">
                                    <i class="bi-arrow-down-right-circle-fill" style="margin-right: 5px;"></i>
                                    Étape suivante
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 2000 à 5000 -->
<!-- réponse oui pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget > 2000 && session.budget <= 5000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu sais ce que tu veux, et avec {{session.budget | currency}}, tu as les moyens de structurer un
                        business solide.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu sais ce que tu veux, et avec {{session.budget | currency}}, tu as les moyens de structurer un
                        business solide.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Avec ce budget, tu entres dans une nouvelle dimension. Ce n’est plus un simple test, mais
                            une opportunité de poser les bases solides de ton activité.
                        </p>
                        <hr>
                        <p>
                            Avec une idée claire en tête et un capital suffisant, tu es prêt à optimiser tes coûts,
                            négocier efficacement et sécuriser ta chaîne logistique.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Commande des volumes stratégiques</h2>
                            <p class="text-black mb-0 pt-4">Tu travailles avec des fournisseurs fiables et commandes des
                                volumes adaptés pour négocier de meilleurs prix.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu sécurises des contrats pour éviter les mauvaises
                                surprises.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Investis dans la logistique adaptée</h2>
                            <p class="text-black mb-0 pt-4">Tu choisis des Incoterms comme FOB ou CIF pour optimiser tes
                                coûts.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu privilégies le transport en groupage pour économiser sur
                                les frais logistiques.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Peaufine ton produit</h2>
                            <p class="text-black mb-0 pt-4">Tu t’assures que ton produit respecte les normes européennes
                                (CE, RoHS, etc.).</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu personnalises le produit pour augmenter sa valeur perçue
                                (packaging, design).</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, chaque euro investi te rapproche d’un business rentable. Il est temps de
                                structurer ton projet avec précision et stratégie pour garantir ton succès.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 2000 à 5000 -->
<!-- réponse non pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '02' && session.budget > 2000 && session.budget <= 5000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, tu peux explorer de nombreuses idées pour ton projet.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, tu peux explorer de nombreuses idées pour ton projet.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te permet d’analyser, tester et valider plusieurs produits avant de lancer ton
                            activité. Avec les bonnes informations, tu pourras rapidement identifier des opportunités
                            lucratives.
                        </p>
                        <hr>
                        <p>
                            Ton objectif : explorer le marché, tester les niches stratégiques et poser les bases d’un
                            projet durable.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Explore les opportunités</h2>
                            <p class="text-black mb-0 pt-4">Analyse les tendances sur des plateformes comme Amazon, Etsy
                                ou eBay.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Identifie des secteurs en forte croissance comme le
                                bien-être, la maison ou les accessoires.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Teste tes idées</h2>
                            <p class="text-black mb-0 pt-4">Importe de petites quantités sur plusieurs niches pour
                                valider leur potentiel.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Utilise les retours clients pour ajuster et affiner ton
                                produit.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Cible les produits stratégiques</h2>
                            <p class="text-black mb-0 pt-4">Privilégie des produits légers, faciles à transporter et à
                                forte valeur ajoutée.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Focus sur les attentes des consommateurs européens :
                                écologiques, innovants ou abordables.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Ne pas avoir d’idée claire n’est pas un frein, c’est une opportunité. Utilise ce budget
                                pour explorer et trouver le produit parfait qui marquera le début de ton succès.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 5 000 à 15 000 -->
<!-- Réponse OUI -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget > 5000 && session.budget <= 15000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, tu as les moyens de bâtir un business qui génère des profits
                        durables.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, tu as les moyens de bâtir un business qui génère des profits
                        durables.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Avec ce capital, tu peux négocier avec des fournisseurs pour obtenir des produits de
                            qualité, organiser une logistique optimisée et personnaliser ton offre pour te démarquer.
                        </p>
                        <hr>
                        <p>
                            Tu es maintenant en position de structurer une entreprise robuste qui t’offre des bénéfices
                            croissants.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Commande des volumes significatifs</h2>
                            <p class="text-black mb-0 pt-4">Tu commandes des volumes qui réduisent tes coûts unitaires
                                et augmentent tes marges.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu négocies des délais de paiement favorables avec tes
                                fournisseurs.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Améliore ta logistique</h2>
                            <p class="text-black mb-0 pt-4">Tu utilises des Incoterms comme CIF ou DDP pour garantir des
                                livraisons sécurisées.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu collabores avec des transitaires expérimentés pour
                                réduire les risques.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Crée une offre irrésistible</h2>
                            <p class="text-black mb-0 pt-4">Tu personnalises ton produit avec un packaging de qualité et
                                un branding attrayant.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu renforces la valeur perçue de ton produit pour capter une
                                clientèle premium.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu es prêt à structurer un business solide. Ce n’est plus une idée, c’est
                                une stratégie en action. Fais de chaque étape un tremplin vers le succès.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 5 000 à 15 000 -->
<!-- Réponse NON -->
<section class="pt-5" *ngIf="session.step1.response === '02' && session.budget > 5000 && session.budget <= 15000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu as {{session.budget | currency}}, mais pas encore d’idée ? C’est l’opportunité de trouver le
                        produit parfait.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu as {{session.budget | currency}}, mais pas encore d’idée ? C’est l’opportunité de trouver le
                        produit parfait.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Avec ce capital, tu peux explorer de nombreuses niches, tester des idées prometteuses et
                            valider les meilleures opportunités pour te lancer.
                        </p>
                        <hr>
                        <p>
                            Ton objectif : identifier un produit à forte valeur ajoutée et créer une stratégie claire
                            pour conquérir ton marché.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Analyse les niches rentables</h2>
                            <p class="text-black mb-0 pt-4">Tu explores les tendances sur des marketplaces comme Amazon
                                ou Etsy.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu cibles des produits à forte demande dans des secteurs
                                comme la maison ou le bien-être.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Teste les produits prometteurs</h2>
                            <p class="text-black mb-0 pt-4">Tu commandes de petites quantités pour tester plusieurs
                                idées en parallèle.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu utilises les retours pour améliorer ton offre et définir
                                la niche idéale.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Priorise les niches stratégiques</h2>
                            <p class="text-black mb-0 pt-4">Tu te concentres sur des produits légers, innovants et
                                conformes aux normes européennes.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu favorises des niches avec une forte valeur perçue pour
                                maximiser tes marges.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Ce budget te permet de poser des bases solides. Explore les options, teste, et choisis
                                une niche stratégique pour te lancer efficacement.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 15 000 à 25 000 -->
<!-- Réponse OUI -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget > 15000 && session.budget <= 25000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, tu es prêt à construire un business rentable et ambitieux.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, tu es prêt à construire un business rentable et ambitieux.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te permet d’acheter en volume, de négocier avec des fournisseurs de qualité, et de
                            structurer une logistique efficace. Tu passes d’une idée à une exécution solide.
                        </p>
                        <hr>
                        <p>
                            C’est le moment de déployer des stratégies qui transforment ton capital en un business
                            robuste et scalable.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Passe à l’échelle supérieure</h2>
                            <p class="text-black mb-0 pt-4">Tu commandes des quantités significatives pour bénéficier
                                d’économies d’échelle.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu sécurises des accords de long terme avec des fournisseurs
                                fiables.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Structure ta logistique</h2>
                            <p class="text-black mb-0 pt-4">Tu choisis des solutions de transport optimisées
                                (conteneurs, groupage, etc.).</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu utilises des Incoterms comme CIF ou DDP pour garantir une
                                chaîne logistique fiable.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Développe ton branding</h2>
                            <p class="text-black mb-0 pt-4">Tu investis dans un packaging unique pour améliorer la
                                valeur perçue de ton produit.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu renforces ton branding pour te positionner sur un segment
                                premium.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu as tout ce qu’il faut pour bâtir un business solide. Tu poses les
                                bases d’une entreprise rentable et durable. Chaque étape te rapproche de ton objectif.
                            </p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 15 000 à 25 000 -->
<!-- Réponse NON -->
<section class="pt-5" *ngIf="session.step1.response === '02' && session.budget > 15000 && session.budget <= 25000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Il est temps de découvrir ton
                        marché idéal.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Il est temps de découvrir ton
                        marché idéal.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te permet d’explorer les meilleures opportunités de marché et de valider des idées
                            avant de passer à l’action.
                        </p>
                        <hr>
                        <p>
                            Ton objectif : utiliser ce capital pour trouver une niche rentable et établir une base
                            solide pour ton business.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Identifie des marchés rentables</h2>
                            <p class="text-black mb-0 pt-4">Tu analyses les tendances sur des marketplaces et repères
                                des niches prometteuses.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu te concentres sur des secteurs à forte valeur perçue
                                comme la santé ou le lifestyle.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Teste plusieurs produits</h2>
                            <p class="text-black mb-0 pt-4">Tu importes de petites quantités sur différentes niches pour
                                mesurer la demande.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu utilises des outils comme Google Trends pour valider tes
                                choix stratégiques.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Investis dans la recherche produit</h2>
                            <p class="text-black mb-0 pt-4">Tu explores les tendances actuelles et cibles des produits
                                innovants et écologiques.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu priorises des idées qui répondent à des besoins réels et
                                des problèmes spécifiques.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Ce budget est une opportunité exceptionnelle. Explore, teste et choisis une niche
                                stratégique pour construire une activité rentable et durable.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 25 000 à 50 000 -->
<!-- Réponse OUI -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget > 25000 && session.budget <= 50000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, tu peux construire un empire commercial solide.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, tu peux construire un empire commercial solide.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te permet de t’imposer sur le marché, d’investir dans des campagnes marketing
                            percutantes, et d’assurer une qualité supérieure pour tes produits.
                        </p>
                        <hr>
                        <p>
                            Tu es prêt à maximiser ton retour sur investissement grâce à une stratégie précise et des
                            partenariats solides.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Commande en gros pour réduire les coûts</h2>
                            <p class="text-black mb-0 pt-4">Tu commandes de grandes quantités pour bénéficier des
                                meilleures marges.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu négocies des contrats à long terme pour sécuriser des
                                conditions avantageuses.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Investis dans la logistique premium</h2>
                            <p class="text-black mb-0 pt-4">Tu optes pour des solutions logistiques fiables comme le
                                transport maritime en conteneur complet (FCL).</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu sécurises une assurance complète pour minimiser les
                                risques.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Renforce ton positionnement</h2>
                            <p class="text-black mb-0 pt-4">Tu développes un branding premium pour capturer une
                                clientèle haut de gamme.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu investis dans des campagnes publicitaires pour maximiser
                                ta visibilité.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu es prêt à jouer dans la cour des grands. Chaque décision stratégique
                                te rapproche d’une activité rentable et pérenne.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 25 000 à 50 000 -->
<!-- Réponse NON -->
<section class="pt-5" *ngIf="session.step1.response === '02' && session.budget > 25000 && session.budget <= 50000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Découvre ton marché idéal.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Découvre ton marché idéal.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te permet d’explorer des niches prometteuses, de tester des idées de produits et
                            d’analyser des marchés à fort potentiel.
                        </p>
                        <hr>
                        <p>
                            Utilise ce capital pour valider tes hypothèses et poser les bases d’un business solide.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Explore des niches rentables</h2>
                            <p class="text-black mb-0 pt-4">Tu utilises des outils comme Jungle Scout ou Helium 10 pour
                                analyser les opportunités.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu identifies des produits innovants ou à forte demande dans
                                des secteurs spécifiques.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Valide tes idées avec des tests</h2>
                            <p class="text-black mb-0 pt-4">Tu commandes des échantillons pour tester la qualité et la
                                demande.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu utilises des retours clients pour affiner ton choix de
                                produit.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Identifie des segments prometteurs</h2>
                            <p class="text-black mb-0 pt-4">Tu te concentres sur des produits qui répondent à des
                                besoins spécifiques et qui ont un potentiel de personnalisation.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu privilégies des niches avec des marges élevées et une
                                faible concurrence.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu as une marge de manœuvre importante pour explorer, tester et
                                construire une activité qui génère des profits constants.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 50 000 à 100 000 -->
<!-- Réponse OUI -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget > 50000 && session.budget <= 100000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, tu as les moyens d'établir une marque incontournable.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, tu as les moyens d'établir une marque incontournable.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te permet de dominer ton marché, de lancer une stratégie marketing globale et de
                            t’assurer un contrôle total sur ta chaîne d’approvisionnement.
                        </p>
                        <hr>
                        <p>
                            Tu peux maintenant poser les bases d’une entreprise internationale et générer des marges
                            conséquentes.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Commande en gros et réduis tes coûts</h2>
                            <p class="text-black mb-0 pt-4">Tu négocies des quantités importantes pour maximiser tes
                                marges.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu signes des partenariats stratégiques avec des
                                fournisseurs de confiance.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Développe une infrastructure robuste</h2>
                            <p class="text-black mb-0 pt-4">Tu investis dans des entrepôts, des outils logistiques, et
                                des plateformes de gestion.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu utilises des Incoterms comme DDP pour garantir une
                                logistique sans faille.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Déploie une stratégie marketing mondiale</h2>
                            <p class="text-black mb-0 pt-4">Tu lances des campagnes publicitaires multi-canaux pour
                                maximiser ta portée.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu développes des collaborations avec des influenceurs pour
                                booster ta visibilité.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu es prêt à passer à un niveau supérieur et à t'imposer comme un acteur
                                majeur sur ton marché. Chaque étape te rapproche du succès.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 50 000 à 100 000 -->
<!-- Réponse NON -->
<section class="pt-5" *ngIf="session.step1.response === '02' && session.budget > 50000 && session.budget <= 100000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Explore les meilleures
                        opportunités.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Explore les meilleures
                        opportunités.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te donne une flexibilité exceptionnelle pour tester plusieurs niches, analyser les
                            tendances et explorer les marchés à fort potentiel.
                        </p>
                        <hr>
                        <p>
                            Utilise ton capital pour découvrir des opportunités uniques et bâtir un projet rentable et
                            ambitieux.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Explore des marchés à forte demande</h2>
                            <p class="text-black mb-0 pt-4">Tu analyses des données de marché pour repérer des niches
                                prometteuses.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu identifies des produits innovants qui répondent aux
                                besoins actuels des consommateurs.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Valide tes choix avec des analyses poussées</h2>
                            <p class="text-black mb-0 pt-4">Tu utilises des outils d’analyse comme SEMrush ou Google
                                Trends pour valider les tendances.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu mises sur des produits avec une forte valeur ajoutée pour
                                maximiser tes marges.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Teste des niches avec des échantillons</h2>
                            <p class="text-black mb-0 pt-4">Tu commandes des échantillons pour tester plusieurs produits
                                et valider leur qualité.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu investis dans des études de marché pour affiner ton choix
                                de produit.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu as une opportunité unique de devenir un leader sur ton marché. Profite
                                de ta flexibilité pour tester, apprendre et te positionner stratégiquement.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 100 000 et plus -->
<!-- Réponse OUI -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget > 100000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, tu peux bâtir une entreprise d’envergure internationale.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, tu peux bâtir une entreprise d’envergure internationale.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget t’ouvre les portes d’un marché mondial, te permet d’accéder aux meilleures
                            technologies et de mettre en place une infrastructure commerciale robuste.
                        </p>
                        <hr>
                        <p>
                            Tu es prêt à structurer une entreprise performante, à maximiser tes profits et à étendre ta
                            présence à l’échelle internationale.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Développe une chaîne d’approvisionnement mondiale</h2>
                            <p class="text-black mb-0 pt-4">Tu établis des partenariats solides avec des fournisseurs
                                pour garantir une qualité constante.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu négocies des contrats à long terme pour sécuriser des
                                coûts compétitifs et des délais optimaux.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Investis dans l’automatisation et l’innovation</h2>
                            <p class="text-black mb-0 pt-4">Tu intègres des technologies avancées pour gérer
                                efficacement tes stocks et ta logistique.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu utilises des outils de prévision pour anticiper la
                                demande et optimiser tes investissements.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Mets en place une stratégie marketing globale</h2>
                            <p class="text-black mb-0 pt-4">Tu développes des campagnes multi-plateformes pour capturer
                                des parts de marché importantes.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu renforces ton image de marque en collaborant avec des
                                influenceurs et en sponsorisant des événements clés.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu peux transformer une vision ambitieuse en réalité. Tu es en position
                                de dominer ton secteur et de générer une croissance durable et significative.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 100 000 et plus -->
<!-- Réponse NON -->
<section class="pt-5" *ngIf="session.step1.response === '02' && session.budget > 100000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Identifie les marchés les plus
                        lucratifs.
                    </h3>
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec {{session.budget | currency}}, mais pas encore d’idée ? Identifie les marchés les plus
                        lucratifs.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Ce budget te donne la capacité d’explorer des marchés mondiaux, de tester plusieurs niches à
                            grande échelle et de valider tes hypothèses rapidement.
                        </p>
                        <hr>
                        <p>
                            C’est le moment de structurer une stratégie globale pour identifier le produit ou la niche
                            parfaite.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">La Feuille de Route</h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Analyse des données de marché</h2>
                            <p class="text-black mb-0 pt-4">Tu utilises des plateformes comme Statista ou Euromonitor
                                pour comprendre les tendances.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu identifies des secteurs émergents ou sous-exploités avec
                                un fort potentiel.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Teste des produits à fort potentiel</h2>
                            <p class="text-black mb-0 pt-4">Tu commandes des échantillons pour valider leur qualité et
                                leur attrait.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu analyses les retours pour affiner tes choix et identifier
                                le produit phare.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Structure une stratégie à long terme</h2>
                            <p class="text-black mb-0 pt-4">Tu élabores un plan marketing et opérationnel basé sur des
                                données précises.</p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">Tu crées un réseau de partenaires pour t'assurer une
                                croissance durable.</p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            <p>Avec ce budget, tu as une opportunité exceptionnelle de construire un projet ambitieux et
                                rentable. Profite de cette marge pour poser les bases solides de ton activité.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>