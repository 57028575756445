import { Component } from '@angular/core';
import { EbookService } from '../../services/ebook.service';
import { FacebookService } from '../../services/facebook.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-product-ebook01',
  templateUrl: './product-ebook01.component.html',
  styleUrls: ['./product-ebook01.component.css']
})
export class ProductEbook01Component {

  themeColor: any = '#C7AC97';

  error: any;
  checkoutButton: any = true;
  checkoutButtonLoading: any = false;
  // Prix actuel du produit
  currentPrice: number = 0;
  // Prix initial du produit
  price: number = 17.97;
  // Prix promotionnel 1 après expiration du compte à rebours
  pricePromo01: number = 17.97;
  // Prix promotionnel 2 pendant le compte à rebours
  pricePromo02: number = 7.97;


  
  constructor(private router: Router, private ebookService: EbookService, private facebookService: FacebookService) { }

  ngOnInit(): void {

  // Chargement des scripts externes Wistia
  this.loadExternalScript('https://fast.wistia.com/embed/medias/zmlkvt8b1i.jsonp');
  this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');

    this.checkoutButton = true;
    this.checkoutButtonLoading = false;
    this.currentPrice = this.pricePromo02;

    this.facebookService.init(environment.products.ebooks.ebook01.pixel);
    this.facebookService.track('PageView');    
    this.facebookService.sendEventToFacebook(environment.products.ebooks.ebook01.pixel, environment.products.ebooks.ebook01.accessToken, 'PageView');
  }

  // Fonction pour charger un script externe
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  // Fonction pour gérer le processus de paiement
  async checkout(ref: any) {
    this.checkoutButton = false;
    this.checkoutButtonLoading = true;

    // Assignation du prix actuel à l'environnement du produit
    // environment.products.ebooks.ebook01.price = this.currentPrice;

    if (ref === 'starter') {    
      environment.products.ebooks.ebook01.ref = 't1-comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame';
      environment.products.ebooks.ebook01.price = 12;
    } else {    
      environment.products.ebooks.ebook01.ref = 't1-comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame-p';
      environment.products.ebooks.ebook01.price = 27;
    }


    let data = {
      id: environment.products.ebooks.ebook01.id,
      ref: environment.products.ebooks.ebook01.ref,
      price: environment.products.ebooks.ebook01.price,
      product: environment.products.ebooks.ebook01,
      pixel: environment.products.ebooks.ebook01.pixel,
      accessToken: environment.products.ebooks.ebook01.accessToken,
      themeColor: this.themeColor,
      checkoutCancelUrl: environment.ebookCheckoutCancelUrl,
      checkoutSuccessUrl: environment.ebookCheckoutSuccessUrl,
      stripe: environment.stripe
    }

    // Suivi des événements Facebook pour 'AddToCart'
    this.facebookService.init(environment.products.ebooks.ebook01.pixel);
    this.facebookService.track('AddToCart', {
      content_name: environment.products.ebooks.ebook01.ref,
      content_ids: environment.products.ebooks.ebook01.id,
      content_type: 'product',
      value: environment.products.ebooks.ebook01.price,
      currency: environment.products.ebooks.ebook01.devise,
      quantity: 1
    });

    let cartObject = {
      content_name: environment.products.ebooks.ebook01.ref,
      content_ids: environment.products.ebooks.ebook01.id,
      content_type: 'product',
      value: environment.products.ebooks.ebook01.price,
      currency: environment.products.ebooks.ebook01.devise,
      quantity: 1
    }

    // Suivi des événements via api Facebook pour 'AddToCart'
    this.facebookService.sendEventToFacebook(
      environment.products.ebooks.ebook01.pixel,
      environment.products.ebooks.ebook01.accessToken,
      'AddToCart',
      '',
      cartObject
    );

    // Appel au service de checkout pour redirection vers Stripe
    this.ebookService.checkout(data).subscribe({
      next: (response: any) => {
        /* success callback */
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        window.location.href = response.session.url;;
      },
      error: (error: any) => {
        /* error callback */
        this.error = true;
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        setTimeout(() => {
          this.error = null;
        }, 3500); // 500 millisecondes = 0.5 seconde       
      }
    });
  }

  // Fonction pour faire défiler la page vers un élément spécifique
scrollToElement(elementId: string): void {
  const element = document.querySelector(`#${elementId}`);
  if(element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
}
