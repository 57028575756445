<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>

<section class="pt-4">
    <div class="container center pt-1">
        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
        <h3 class="display-4 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
            Découvre comment passer de<span class="color-important-word"> 0 à 5.000€ / mois </span>sur Instagram et
            TikTok
            avec l'intelligence artificielle
        </h3>
        <!-- Contenu visible uniquement sur les smartphones -->
        <h3 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
            Découvre comment passer de<span class="color-important-word"> 0 à 5.000€ / mois </span>sur Instagram et
            TikTok
            avec l'intelligence artificielle
        </h3>
    </div>
</section>


<!-- Section video -->
<section class="video-vsl">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="clear"></div>
                <div class="text-center">

                    <!-- <div *ngIf="showMobileVideo === true" class="wistia_responsive_padding mb-5"
                        style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_skqifk74yy seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/skqifk74yy/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div *ngIf="showDesktopVideo === true" class="wistia_responsive_padding mb-5" -->
                    <div class="wistia_responsive_padding mb-5" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_qr0jrj6vwg seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/qr0jrj6vwg/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="d-none d-md-block">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill "></i>JE PASSE A L'ACTION</a>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class=" d-block d-md-none">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-small button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill"></i>JE PASSE A L'ACTION</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="douleur-section">
    <div class="content-wrap">
        <!-- <div class="page-section"> -->
        <div class="container ">
            <div class="text-center mb-4 douleur-section-text-title">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    300 millions d'emplois pourraient disparaître... et si le tien était le prochain ?
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none douleur-section-text-title">
                    300 millions d'emplois pourraient disparaître... et si le tien était le prochain ?
                </h6>
            </div>
        </div>
        <div class="container container-sam">

            <div class="text-center">

                <img src="assets/images/simulator/sam-altman.png">
                <p class="lead">Sam Altman, le PDG d’OpenAI, annonce l’arrivée d’une superintelligence dans les 1 000
                    prochains jours, capable de bouleverser le monde tel que nous le connaissons. Cette révolution va
                    transformer des industries entières, et des millions de personnes risquent de voir leur emploi
                    disparaître.
                    <br>
                    <br>
                    Et toi, que fais-tu ? Tu observes ce changement, ou tu décides d’en tirer parti avant qu’il ne soit
                    trop tard ? Ignorer cette réalité, c’est choisir de rester en retrait. Mais agir maintenant, c’est
                    transformer cette menace en opportunité et t’assurer un avenir où tu es aux commandes.
                    <br>
                    <br>
                    Le monde évolue vite. Et si tu pouvais utiliser une plateforme comme Instagram ou/et TikTok pour
                    générer des
                    revenus, sans jamais montrer ton visage ? Et si cette transformation devenait ton levier pour
                    sécuriser ton avenir ?
                </p>
            </div>
        </div>

        <div class="text-center">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <div class="d-none d-md-block pt-4" style="margin-bottom: -45px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill "></i>JE PASSE A L'ACTION</a>
            </div>
            <!-- Contenu visible uniquement sur les smartphones -->
            <div class="d-block d-md-none pt-5" style="margin-bottom: -50px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-small button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>JE PASSE A L'ACTION</a>
            </div>
        </div>
        <!-- </div> -->
    </div>
</section>

<section class="mockup-section">
    <div class="content-wrap">
        <div class="container">

            <div class="text-center mb-6 mockup-section-text-title">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    Chaque étape pour créer ta communauté
                    <span class="color-important-word">Instagram et TikTok</span> aujourd’hui avec la méthode du
                    faceless <span class="color-important-word">sans avoir besoin de montrer ton visage</span>
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                    Chaque étape pour créer ta communauté
                    <span class="color-important-word">Instagram et TikTok</span> aujourd’hui avec la méthode du
                    faceless <span class="color-important-word">sans avoir besoin de montrer ton visage</span>
                </h6>
            </div>

            <div class="row gallery-categories gutter-20 mockup-section-gallery">
                <div class="col-md-12">
                    <div
                        style="background: url('assets/images/ebooks/ebook03/mockup-01.jpg') no-repeat center center; background-size: cover; height: 450px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook03/mockup-02.jpg') no-repeat center center; background-size: cover; height: 250px;">
                    </div>
                </div>

                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook03/mockup-03.jpg') no-repeat 50% 40%; background-size: cover; height: 250px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook03/mockup-04.jpg') no-repeat 100% 100%; background-size: cover; height: 250px;">
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>

<section class="section-simulator" style="background-color: #000000;   position: relative; z-index: 1;">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center mb-5 section-simulator-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block text-white mb-5">

                        Estime tes revenus potentiels en fonction de ton audience et de tes produits
                    </h6>
                    <span class="fw-bolder text-white d-none d-md-block pt-3">Avec une communauté de <span
                            class="span-simulator">{{ followers }} abonnés</span> tu peux générer au minimum

                        <span class="span-simulator">{{ projectedRevenue | currency:'EUR':'symbol':'1.0-0':'fr' }}
                            par mois</span> </span>

                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none text-white">
                        Estime tes revenus potentiels en fonction de ton audience et de tes produits
                    </h6>
                    <span class="fw-bolder text-white d-block d-md-none pt-3">Avec une communauté de<span
                            class="span-simulator"> {{ followers }} abonnés</span> tu peux générer au minimum

                        <span class="span-simulator ">{{ projectedRevenue | currency:'EUR':'symbol':'1.0-0':'fr' }}
                            par mois</span></span>

                </div>
                <div class="slider-container pt-5">
                    <h3 class="text-white fw-bolder">Revenu mensuel </h3>
                    <!-- <h2 class="text-white">{{ projectedRevenue | currency:'EUR' }}</h2> -->
                    <h2 class="text-white fw-bolder projectRevenue" style="margin-top: -30px;">{{ projectedRevenue |
                        currency:'EUR':'symbol':'1.0-0':'fr' }}</h2>




                    <div class="slider">
                        <label for="followers text-white fw-bolder">Nombre d'abonnés</label>
                        <input type="range" id="followers" [(ngModel)]="followers" (input)="updateRevenue()" min="300"
                            max="50000" step="1" />
                        <span class="span-followers fw-bolder">{{ followers }} abonnés</span>
                    </div>

                    <div class="slider pt-5">
                        <label for="conversionRate fw-bolder">Taux de conversion (%)</label>
                        <input type="range" id="conversionRate" [(ngModel)]="conversionRate" (input)="updateRevenue()"
                            min="0.1" max="10" step="0.1" />
                        <span class="span-conversionRate fw-bolder">{{ conversionRate }}%</span>
                    </div>

                    <div class="slider pt-5">
                        <label for="price text-white fw-bolder">Prix de vente du produit</label>
                        <input type="range" id="price" [(ngModel)]="productPrice" (input)="updateRevenue()" min="10"
                            max="500" step="1" />
                        <span class="span-daily-customer fw-bolder">{{ productPrice | currency }}</span>
                    </div>

                </div>
                <div class="text-center pt-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="d-none d-md-block">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill "></i>JE PASSE A L'ACTION</a>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class="d-block d-md-none" style="margin-bottom: -35px;">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-small button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill"></i>JE PASSE A L'ACTION</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-newsletter" style="margin-bottom: -200px;  z-index: 2;">
    <div class="content-wrap">
        <div class="section text-center text-white" style="background: #a50000;">
            <!-- Bandeau défilant -->
            <div class="ticker-wrap">
                <div class="ticker">
                    <div class="ticker-item">Newsletter <img src="assets/images/ebooks/ebook03/Newsletter-logo.png"
                            width="180px"> à vie incluse</div>
                    <div class="ticker-item">★</div>
                    <div class="ticker-item">Offre exclusive aujourd'hui</div>
                    <div class="ticker-item">★</div>
                    <div class="ticker-item">Guide de la méthode Faceless</div>
                    <div class="ticker-item">★</div>
                    <div class="ticker-item">Newsletter<img src="assets/images/ebooks/ebook03/Newsletter-logo.png"
                            width="180px"> à vie incluse</div>
                    <div class="ticker-item">★</div>
                    <div class="ticker-item">Offre exclusive aujourd'hui</div>
                    <div class="ticker-item">★</div>
                    <div class="ticker-item">Guide de la méthode Faceless</div>
                </div>
            </div>

            <!-- Contenu principal -->
            <div class="container py-5">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <p class="text-uppercase fw-bold mb-4">UNE OFFRE IRRÉSISTIBLE POUR BOOSTER TES RESEAUX</p>
                        <h1 class="text-white fw-bold display-3" style="letter-spacing: -1px;">Newsletter à vie + Guide
                            de la méthode Faceless</h1>
                        <p class="op-07 mw-xs mx-auto px-5 mb-5">
                            Profite d’un accès exclusif à notre newsletter business Propul's à vie et découvre le Guide
                            de la méthode faceless.
                            Transforme ton audience en une machine à revenus, sans jamais manquer une opportunité.
                        </p>
                        <!-- <a href="#" class="button button-xlarge rounded-pill button-light m-0 mt-2">OBTENIR LA STRATEGIE</a> -->
                        <div class="text-center" style="margin-bottom: -90px;">
                            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                            <div class="d-none d-md-block">
                                <a (click)="scrollToElement('section-pricing')"
                                    class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color-v1"><i
                                        class="bi-arrow-down-right-circle-fill "></i>JE PASSE A L'ACTION</a>
                            </div>
                            <!-- Contenu visible uniquement sur les smartphones -->
                            <div class="d-block d-md-none" style="margin-bottom: -110px;">
                                <a (click)="scrollToElement('section-pricing')"
                                    class="button button-small button-circle button-green fw-bolder call-to-action-background-color-v1"><i
                                        class="bi-arrow-down-right-circle-fill"></i>JE PASSE A L'ACTION</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section>
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block text-black mb-5">
                        On te présente Marc et Melissa, deux créateurs de contenu révolutionnaires, conçus grâce à
                        l’Intelligence Artificielle !
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none text-black">
                        On te présente Marc et Melissa, deux créateurs de contenu révolutionnaires, conçus grâce à
                        l’Intelligence Artificielle !
                    </h6>
                </div>

                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <img src="assets/images/ebooks/ebook03/mood-l-m.png" class="mb-4 pt-2 d-none d-md-block">
                <!-- Contenu visible uniquement sur les smartphones -->
                <img src="assets/images/ebooks/ebook03/mood-port.png" class="mb-2 pt-2 d-block d-md-none">



                <div class="row align-items-stretch justify-content-center center">
                    <div class="col-12">
                        <span class="fw-bolder">Et si je te disais que toi aussi, tu peux créer ta propre Melissa ou ton
                            propre Marc ?<br>
                            Avec les outils d’intelligence artificielle disponibles aujourd’hui, tu as la possibilité de
                            concevoir des assistants virtuels sur-mesure, adaptés à tes besoins spécifiques.
                            Melissa et Marc ne sont pas juste des IA : ce sont des alliés stratégiques, des
                            accélérateurs de productivité, et des moteurs d’innovation.<br>
                            <i>Alors, pourquoi ne pas imaginer ta propre version de Melissa ou Marc, celle qui
                                propulsera ton projet vers de nouveaux horizons ?</i></span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-ia" style="background-color: #000000;">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-ia-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative text-white d-none d-md-block">
                        +250 outils IA à ton service
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative text-white d-block d-md-none">
                        +250 outils IA à ton service
                    </h6>
                    <p class="text-white">L’extrême technologie : intelligence artificielle avec ChatGPT, MidJourney et
                        les centaines de
                        plateformes qui émergent pour remplacer des millions d’emplois dans le monde …</p>
                </div>

                <div class="scroll-container pt-2">
                    <div class="scroll-track" #scrollTrack>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-01.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-02.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-03.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-04.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-05.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-06.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-07.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-08.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-09.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-10.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-12.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-13.png" alt="Investor Image"
                                        width="54px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-opportunite" style="background-color: #FFF2DE; position: relative; z-index: 4;">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-opportunite-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Les opportunités avec une communauté
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Les opportunités avec une communauté
                    </h6>
                    <p>Transforme ton audience en une source de revenus grâce à ces trois leviers incontournables</p>

                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5 pt-4">
                    <div class="col-md-6 col-lg-6 col-xl-4">
                        <div class="justify-content-center grid-inner bg-contrast-0 border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                            data-class="dark:bg-contrast-100">
                            <div class="d-flex fs-1 color mb-3 justify-content-center">
                                <!-- <i class="bi-magic"></i> -->
                                <img src="assets/images/ebooks/ebook03/e-commerce.png" width="80px" class="center">
                            </div>
                            <h3 class="fs-5 mb-2 fw-bolder">E-commerce</h3>
                            <p class="mb-4 fw-light">Vends tes produits directement à ton audience. Transforme tes
                                abonnés en clients en proposant des articles physiques ou
                                numériques, facilement accessibles depuis ton profil.</p>
                            <div class="bg-icon bi-layout-wtf color"
                                style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1"></div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                        <div class="grid-inner bg-contrast-0 border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                            data-class="dark:bg-contrast-100">
                            <div class="d-flex fs-1 color mb-3 justify-content-center">
                                <!-- <i class="bi-magic"></i> -->
                                <img src="assets/images/ebooks/ebook03/product-placement.png" width="80px"
                                    class="center">
                            </div>
                            <h3 class="fs-5 mb-2 fw-bolder">Un placement de produit</h3>
                            <p class="mb-4 fw-light">Collabore avec des marques pour promouvoir leurs produits.
                                Transforme ta communauté en une source de revenus grâce à des partenariats rémunérés.
                            </p>
                            <div class="bg-icon bi-layout-wtf color"
                                style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1"></div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                        <div class="grid-inner bg-contrast-0 border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                            data-class="dark:bg-contrast-100">
                            <div class="d-flex fs-1 color mb-3 justify-content-center">
                                <!-- <i class="bi-magic"></i> -->
                                <img src="assets/images/simulator/freelance.png" width="80px" class="center">
                            </div>
                            <h3 class="fs-5 mb-2 fw-bolder">Un service en ligne</h3>
                            <p class="mb-4 fw-light">Propose des consultations, formations ou services adaptés à ton
                                expertise. Utilise tes réseaux pour convertir ton audience en clients fidèles.</p>
                            <div class="bg-icon bi-layout-wtf color"
                                style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1"></div>
                        </div>
                    </div>
                    <span class="fw-bolder">Que tu sois créateur de contenu, entrepreneur ou passionné, ta communauté
                        peut devenir une véritable machine à cash. Le secret ? Tirer parti de ces trois
                        opportunités pour maximiser ton revenu.</span>
                </div>
                <div class="text-center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="d-none d-md-block">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill "></i>JE PASSE A L'ACTION</a>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class="d-block d-md-none" style="margin-bottom: -55px;">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-small button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill"></i>OBTENIR LA STRATEGIE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section-programme">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Pensé pour ton succès financier !
                        <br />Voici ce qui t’attend :
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Pensé pour ton succès financier !
                        <br />Voici ce qui t’attend :
                    </h6>
                </div>
                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #A50000;"
                            data-bs-theme="dark">
                            <h3 class="mb-3 display-6 fw-bold"><b>Une newsletter mensuelle pour rester au courant des
                                    derniers outils et techniques marketing.</b></h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <h3 class="mb-3 display-7 fw-bold"><b>Maîtrise l’art de la vidéo courte&nbsp;: captive sans
                                    montrer ton visage</b></h3>
                            <p class="text-muted mb-0">Découvre comment créer des vidéos captivantes sans jamais montrer
                                ton visage ! Grâce à des séquences B-Roll et des outils d’intelligence artificielle, tu
                                apprendras à produire des contenus engageants et professionnels tout en restant anonyme.
                                Ce guide te dévoile toutes les astuces pour captiver ton audience et maximiser ta
                                portée, tout en préservant ta vie privée.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/presentiel.png" data-animate="tada slower infinite" width="100px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Capture l’attention en 3 secondes : des accroches et
                                    CTA qui convertissent</b></h3>
                            <p class="text-muted mb-0">Donne à tes vidéos l’élan qu’elles méritent ! Avec ce guide,
                                découvre comment utiliser des audios tendance, créer des miniatures accrocheuses et
                                optimiser tes légendes pour maximiser ta visibilité. Plonge dans les insights, ajuste ta
                                stratégie, et surpasse les algorithmes pour des résultats impressionnants.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Optimise tes vidéos : booste ta visibilité et gagne en
                                    impact</b></h3>
                            <p class="text-muted mb-0">Donne à tes vidéos l’élan qu’elles méritent ! Avec ce guide,
                                découvre comment utiliser des audios tendance, créer des miniatures accrocheuses et
                                optimiser tes légendes pour maximiser ta visibilité. Plonge dans les insights, ajuste ta
                                stratégie, et surpasse les algorithmes pour des résultats impressionnants.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg " data-bs-theme="dark">
                            <h3 class="mb-3 display-7 fw-bold"><b>Transforme tes vidéos en revenus : monétise avec
                                    succès</b></h3>
                            <p>Fais de tes vidéos un levier de revenus puissant ! Apprends à créer du contenu
                                partageable et mémorable qui attire et engage ton audience sur tous les réseaux sociaux.
                                Découvre comment intégrer des appels à l’action efficaces pour transformer ton audience
                                en clients et tes vidéos en source de revenus.
                            </p>
                            <!-- <img src="images/replay.png" data-animate="tada slower infinite" width="100px" class="center tada slower infinite animated" alt="Feature Icon"> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #A50000;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Reste anonyme, sois impactant : protège ta vie privée
                                    en ligne</b></h3>
                            <p>Crée un impact sans exposer ta vie privée ! Grâce à ce guide, utilise des avatars animés,
                                des voix off, et d’autres techniques pour rester anonyme tout en maximisant ta présence
                                sur les réseaux sociaux vidéo. Bénéficie de tous les avantages du digital sans
                                compromettre ton intimité.
                            </p>
                        </div>
                    </div>

                </div>
                <div class="row justify-content col-mb-50 align-items-stretch pt-5">


                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>Transforme ton avenir avec l’IA et les réseaux sociaux
                                    vidéo</b></h3>
                            <p class="text-muted mb-0">D’ici 2030, l’intelligence artificielle redéfinira plus de 375
                                millions de carrières. Ne reste pas à l’écart ! Grâce à ce guide, découvre comment
                                utiliser l’IA et les vidéos courtes pour bâtir une communauté solide sur les réseaux
                                sociaux, tout en préservant ton anonymat. Rejoins les innovateurs qui ont déjà pris une
                                longueur d’avance. Télécharge ton guide maintenant et deviens un acteur incontournable
                                de l’ère numérique.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<div id="section-pricing" class="page-section py-5 py-lg-6" style="background-color: #000000;">
    <div class="container">
        <div class="text-center">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <h6 class="display-5 fw-bolder ls1 mb-5 position-relative d-none d-md-block text-white">
                Génère de l'argent maintenant !
            </h6>
            <!-- Contenu visible uniquement sur les smartphones -->
            <h6 class="display-6 fw-bolder ls1 mb-5 position-relative d-block d-md-none text-white">
                Génère de l'argent maintenant !
            </h6>

            <h6 class="display-3 fw-bolder ls1 position-relative strike color-red-price-off">
                467€
            </h6>
            <h6 class="display-4 fw-bolder ls1 position-relative strike color-red-price-off" style="margin-top: -28px;">
                397€
            </h6>
            <h6 class="display-5 fw-bolder ls1 position-relative strike color-red-price-off" style="margin-top: -28px;">
                247€
            </h6>
            <img width="380px" src="assets/images/simulator/gif-fleche-prix.gif">
        </div>

        <div id="pricing-switch" class="row justify-content-center pricing gx-lg-5">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                <span
                    class="badge px-3 py-2 mt-2 me-1 bg-white border border-default text-dark rounded-pill fw-bolder position-absolute z-9"
                    style="left: 50px; top: -23px; background-color: #ffffff !important; color: rgb(0, 0, 0) !important;">Le
                    plus populaire</span>
                <div class="pricing-box position-relative rounded-xxl p-5 h-100 d-flex flex-column"
                    style="background-color: #a50000 !important;">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h3 class="fw-bolder fs-1 ls-n-1 mb-1 text-white">Starter</h3>
                            <p class="mb-0 small lh-base text-white">Guide + newsletter mensuelle exclusive.</p>
                        </div>

                        <div class="col-lg-6">
                            <div class="pricing-price fw-bolder text-lg-end pt-lg-0 pb-0">
                                <div class="pts-content pts-content-annually text-white">27€<span
                                        class="d-block fs-6 fw-semibold op-05 mb-0 mt-2 text-white">Paiement
                                        unique</span></div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="pricing-features bg-transparent border-bottom-0 border-width-2 border-black mt-5 pt-5 pb-3">
                        <p class="lead fw-bold mb-3 text-white">Accède au guide complet et reçois chaque mois une
                            newsletter exclusive pour booster ton business.</p>
                        <img src="assets/images/simulator/newsletter-sticker.png">
                    </div>
                    <div class="pricing-action p-0 mt-auto">
                        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                        <div class="d-none d-md-block">
                            <a (click)="checkout('starter')" *ngIf="checkoutButton === true"
                                class="button fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color-v1 rounded-pill mx-0">
                                JE PASSE A L'ACTION <i class="bi-arrow-right"></i></a>
                        </div>
                        <!-- Contenu visible uniquement sur les smartphones -->
                        <div (click)="checkout('starter')" *ngIf="checkoutButton === true" class="d-block d-md-none">
                            <a
                                class="button button-mini fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color-v1 rounded-pill mx-0">
                                JE PASSE A L'ACTION <i class="bi-arrow-right"></i></a>
                        </div>
                        <a *ngIf="checkoutButtonLoading === true"
                            class="button button-large button-circle button-green fw-bolder call-to-action-background-color">
                            <img src="assets/images/loading.gif" width="20px"></a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                <div class="pricing-box position-relative rounded-xxl p-5 h-100 d-flex flex-column bg-contrast-0"
                    style="background-color: #ffffff !important;">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h3 class="fw-bolder fs-1 ls-n-1 mb-1">Premium</h3>
                            <p class="mb-0 small lh-base text-contrast-700">Guide + newsletter mensuelle + call
                                stratégique de 45 min.</p>
                        </div>

                        <div class="col-lg-6">
                            <div class="pricing-price fw-bolder text-lg-end pt-lg-0 pb-0">
                                <div class="pts-content pts-content-annually">197€<span
                                        class="d-block fs-6 fw-semibold op-05 mb-0 mt-2">Paiement unique</span></div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="pricing-features bg-transparent border-bottom-0 border-width-2 border-black mt-5 pt-5 pb-3">
                        <p class="lead fw-bold mb-3">En plus du guide et de la newsletter, bénéficie d’un appel
                            stratégique de 45 minutes pour un accompagnement personnalisé.</p>
                        <img src="assets/images/simulator/call.png">
                    </div>

                    <div class="pricing-action p-0 mt-auto">
                        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                        <div class="d-none d-md-block">
                            <a (click)="checkout('premium')" *ngIf="checkoutButton === true"
                                class="button fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color rounded-pill mx-0">
                                JE PASSE A L'ACTION <i class="bi-arrow-right"></i></a>
                        </div>
                        <!-- Contenu visible uniquement sur les smartphones -->
                        <div (click)="checkout('premium')" *ngIf="checkoutButton === true" class="d-block d-md-none">
                            <a
                                class="button button-mini fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color rounded-pill mx-0">
                                JE PASSE A L'ACTION <i class="bi-arrow-right"></i></a>
                        </div>
                        <a *ngIf="checkoutButtonLoading === true"
                            class="button button-large button-circle button-green fw-bolder call-to-action-background-color">
                            <img src="assets/images/loading.gif" width="20px"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section style="margin-top: 30px;">
    <div class="content-wrap py-0">
        <div id="section-faqs" class="page-section my-5">
            <div class="container">
                <div class="row justify-content-between position-relative">
                    <div class="col-lg-5">
                        <h3 class="display-3 ls-n-3 fw-bolder mb-3">
                            <span class="color-important-word">Questions</span>
                            Fréquemment Posées
                        </h3>
                        <img src="assets/images/simulator/faq-guide.png" alt="...">
                    </div>
                    <div class="col-lg-6 bg-contrast-0 p-5 rounded-xxl pt-6">
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de volumes contient le guide ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Notre "Guide ultime pour lancer son business" est composé de deux volumes :
                                - Le premier volume couvre les bases de la création d'entreprise.
                                - Le deuxième volume est entièrement consacré à l'intelligence artificielle et à son
                                application dans ton business.
                            </div>
                        </div>

                        <div class="line border-contrast-300 my-4"></div>

                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quelle est la durée du plan d'action
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le guide propose un plan d'action sur 30 jours. Cependant, tu peux avancer à ton propre
                                rythme et prendre le temps nécessaire pour bien assimiler chaque étape.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que le guide est remboursable ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Non, en raison de la nature numérique de ce produit, les achats ne sont pas
                                remboursables. Nous sommes convaincus que tu trouveras une valeur énorme dans notre
                                guide.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment vais-je recevoir le guide après l'achat ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Après ton paiement, tu recevras directement par email les deux volumes de notre guide.
                                Vérifie bien ton adresse email lors de la commande pour t'assurer de recevoir les
                                documents sans problème.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Qui puis-je contacter en cas de questions ou de problèmes ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Si tu as des questions ou rencontres des problèmes, n'hésite pas à nous contacter à
                                l'adresse suivante : contact&#64;cestnow.com. Nous sommes là pour t'aider et répondre à
                                toutes tes questions.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que le guide est adapté à tous les niveaux d'entrepreneurs ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Oui, le guide est conçu pour s'adapter aux besoins des entrepreneurs débutants,
                                intermédiaires, et avancés. Chaque section du guide est structurée pour offrir des
                                conseils et des stratégies adaptés à ton niveau actuel.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de temps faut-il pour voir les premiers résultats avec ce guide ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le temps pour voir les résultats peut varier en fonction de ton engagement et de ton
                                rythme de travail. Cependant, en suivant les étapes du plan d'action de 30 jours, tu
                                devrais commencer à voir des progrès significatifs dans ton projet entrepreneurial.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Puis-je partager le guide avec d'autres personnes ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le guide est destiné à un usage individuel et n'est pas conçu pour être partagé.
                                Cependant, si tu connais quelqu'un qui pourrait bénéficier du guide, nous t'encourageons
                                à lui recommander d'en acheter un exemplaire pour lui-même.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</section> -->


<section class="section-faq">
    <div class="content-wrap py-0">
        <div id="section-faqs" class="page-section my-5">
            <div class="container">
                <div class="row justify-content-between position-relative">
                    <div class="col-lg-5">
                        <h3 class="display-3 ls-n-3 fw-bolder mb-3">
                            <span class="color-important-word">Questions</span> Fréquemment Posées
                        </h3>
                        <img src="assets/images/ebooks/ebook03/checkout.jpg" alt="...">
                    </div>
                    <div class="col-lg-6 bg-contrast-0 p-5 rounded-xxl pt-6">
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment puis-je créer des Réels engageants sans montrer mon visage ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Notre guide te montre comment utiliser des techniques comme les séquences B-Roll, les
                                banques d’images et les outils d’intelligence artificielle pour créer des Vidéos
                                captivantes tout en restant anonyme. Tu découvriras également comment monétiser ton
                                compte.
                            </div>
                        </div>

                        <div class="line border-contrast-300 my-4"></div>

                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quels outils d'IA sont inclus dans le guide ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le guide recommande une gamme d’outils pratiques pour t’aider à atteindre tes objectifs.
                                Ils serviront à optimiser des aspects de tes vidéos. Et non, on ne parle pas que de Chat
                                GPT :)
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-il possible de monétiser mes Réels sans montrer mon visage ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Absolument ! Le guide propose des stratégies pour créer du contenu qui attire et engage
                                ton audience, tout en utilisant des appels à l’action pour monétiser tes comptes.

                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Ce guide est-il adapté aux débutants ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Oui, ce guide est conçu pour être accessible à tous, que tu sois débutant ou déjà
                                expérimenté sur Instagram et TikTok.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quelle est la durée de la promotion actuelle ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                La promotion actuelle est limitée dans le temps. Assure-toi de profiter de notre offre
                                spéciale avant qu'elle ne se termine !
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Offrez-vous des remboursements ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Étant donné qu'il s'agit d'un produit numérique, nous n'offrons pas de remboursement
                                après l'achat.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment puis-je obtenir de l'aide si j'ai des questions supplémentaires ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Tu peux nous contacter directement par email à contact&#64;cestnow.com. Nous sommes là
                                pour t’aider !
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</section>

<section style="margin-top: -80px;">
    <div class="content-wrap">

        <div class="container">

            <div class="heading-block mb-5 text-center border-bottom-0 pt-3">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h2 class="text-transform-none fw-bolder d-none d-md-block">
                    Ils ont téléchargé la méthode du <span class="fw-bolder color-important-word">faceless sans avoir
                        besoin de montrer ton visage</span>
                </h2>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h3 class="text-transform-none fw-bolder d-block d-md-none">
                    Ils ont téléchargé la méthode du <span class="fw-bolder color-important-word">faceless sans avoir
                        besoin de montrer ton visage</span>
                </h3>
            </div>

            <div class="row user-testimonials">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Grâce à ce guide, j'ai pu construire une audience
                                fidèle sans jamais montrer mon visage. Les stratégies proposées sont adaptées à ceux qui
                                cherchent à rester anonymes tout en ayant un impact fort sur leur audience."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Nina S.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Ce guide m'a permis de comprendre toutes les astuces
                                pour améliorer mon contenu Instagram sans avoir à montrer mon visage. Les conseils sont
                                clairs et faciles à suivre, même pour quelqu'un comme moi qui débute sur la plateforme."
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sarah M.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"J'étais sceptique au début, mais ce guide a vraiment
                                transformé ma façon de créer du contenu. Les techniques expliquées sont efficaces et
                                m'ont permis de gagner en visibilité tout en restant discrète."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Amélie T.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Le guide est incroyablement bien structuré. Chaque
                                section m'a apporté des insights précieux pour optimiser mes posts. Je recommande
                                vivement à quiconque souhaite améliorer sa présence sur Instagram."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Lucas P.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"J'ai adoré la partie sur l'utilisation des B-Roll et
                                des outils d'IA. Ça m'a ouvert les yeux sur des techniques que je n'aurais jamais
                                imaginées. Mon engagement a considérablement augmenté depuis que j'ai suivi les conseils
                                du guide."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Chloé D.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Ce guide m'a aidée à mieux comprendre les subtilités
                                d'Instagram et à utiliser les bonnes pratiques pour accroître ma visibilité. J'apprécie
                                particulièrement la section sur l'optimisation SEO et les hashtags."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Camille R.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>