import { Component } from '@angular/core';
import { EbookService } from '../../services/ebook.service';
import { FacebookService } from '../../services/facebook.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';

import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
@Component({
  selector: 'app-product-ebook04',
  templateUrl: './product-ebook04.component.html',
  styleUrls: ['./product-ebook04.component.css']
})
export class ProductEbook04Component {


  // Couleur du thème pour le composant, initialisée à #9A42A4
  themeColor: any = '#9A42A4';

  // Variable de gestion d'erreur lors du processus de paiement
  error: any;

  // État d'activation/désactivation du bouton de paiement
  checkoutButton: any = true;

  // Indicateur de chargement pour le bouton de paiement
  checkoutButtonLoading: any = false;

  // Temps initial pour le compte à rebours (15 minutes en secondes)
  private initialTime = 15 * 60; // 15 minutes in seconds

  // Temps restant pour le compte à rebours
  timeLeft: number = 0;

  // Subscription de l'observable utilisée pour le compte à rebours
  subscription: Subscription | null = null;

  // Heure de départ pour le compte à rebours
  startTime: number | null = null;

  // Indicateur pour afficher ou masquer le compte à rebours
  showCountdown: boolean = true;

  // Prix actuel du produit
  currentPrice: number = 0;

  // Prix initial du produit
  price: number = 97;

  // Prix promotionnel 1 après expiration du compte à rebours
  pricePromo01: number = 27;

  // Prix promotionnel 2 pendant le compte à rebours
  pricePromo02: number = 17.97;

  constructor(
    private router: Router,
    private ebookService: EbookService,
    private facebookService: FacebookService
  ) { }

  

ngOnInit(): void {
  // Chargement des scripts externes Wistia
  this.loadExternalScript('https://fast.wistia.com/embed/medias/zmlkvt8b1i.jsonp');
  this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');

  // Initialisation des états du bouton de paiement
  this.checkoutButton = true;
  this.checkoutButtonLoading = false;


  // Initialisation et suivi des événements Facebook
  this.facebookService.init(environment.products.ebooks.ebook04.pixel);
  this.facebookService.track('PageView');
  this.facebookService.sendEventToFacebook(environment.products.ebooks.ebook04.pixel, environment.products.ebooks.ebook04.accessToken, 'PageView');
}

  // Fonction pour charger un script externe
  public loadExternalScript(url: string) {
  const body = <HTMLDivElement>document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}

  // Fonction pour gérer le processus de paiement
  async checkout(ref: any) {
  // Assignation du prix actuel à l'environnement du produit
  // environment.products.ebooks.ebook01.price = this.currentPrice;

  if (ref === 'starter') {    
    environment.products.ebooks.ebook01.ref = 't2-comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame';
    environment.products.ebooks.ebook01.price = 17;
  } else {    
    environment.products.ebooks.ebook01.ref = 't2-comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame-p';
    environment.products.ebooks.ebook01.price = 27;
  }


  this.checkoutButton = false;
  this.checkoutButtonLoading = true;

  let data = {
    id: environment.products.ebooks.ebook01.id,
    ref: environment.products.ebooks.ebook01.ref,
    price: environment.products.ebooks.ebook01.price,
    product: environment.products.ebooks.ebook01,
    pixel: environment.products.ebooks.ebook01.pixel,
    accessToken: environment.products.ebooks.ebook01.accessToken,
    themeColor: this.themeColor,
    checkoutCancelUrl: environment.ebookCheckoutCancelUrl,
    checkoutSuccessUrl: environment.ebookCheckoutSuccessUrl,
    stripe: environment.stripe
  }

  // Suivi des événements Facebook pour 'AddToCart'
  this.facebookService.init(environment.products.ebooks.ebook01.pixel);
  this.facebookService.track('AddToCart', {
    content_name: environment.products.ebooks.ebook01.ref,
    content_ids: environment.products.ebooks.ebook01.id,
    content_type: 'product',
    value: environment.products.ebooks.ebook01.price,
    currency: environment.products.ebooks.ebook01.devise,
    quantity: 1
  });

  let cartObject = {
    content_name: environment.products.ebooks.ebook01.ref,
    content_ids: environment.products.ebooks.ebook01.id,
    content_type: 'product',
    value: environment.products.ebooks.ebook01.price,
    currency: environment.products.ebooks.ebook01.devise,
    quantity: 1
  }

  this.facebookService.sendEventToFacebook(
    environment.products.ebooks.ebook01.pixel,
    environment.products.ebooks.ebook01.accessToken,
    'AddToCart',
    '',
    cartObject
  );

  // Appel au service de checkout pour redirection vers Stripe
  this.ebookService.checkout(data).subscribe({
    next: (response: any) => {
      /* success callback */
      this.checkoutButton = true;
      this.checkoutButtonLoading = false;
      window.location.href = response.session.url;
    },
    error: (error: any) => {
      /* error callback */
      this.error = true;
      this.checkoutButton = true;
      this.checkoutButtonLoading = false;
      setTimeout(() => {
        this.error = null;
      }, 3500); // 500 millisecondes = 0.5 seconde       
    }
  });
}

// Fonction pour faire défiler la page vers un élément spécifique
scrollToElement(elementId: string): void {
  const element = document.querySelector(`#${elementId}`);
  if(element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

}
