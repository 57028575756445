import { Component, ViewChild, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { EbookService } from '../../services/ebook.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { FacebookService } from '../../services/facebook.service';

@Component({
  selector: 'app-product-ebook02',
  templateUrl: './product-ebook02.component.html',
  styleUrls: ['./product-ebook02.component.css']
})
export class ProductEbook02Component {
  @ViewChildren('scrollTrack') scrollTracks!: QueryList<ElementRef>;


  error: any;
  // Couleur du thème pour le composant, initialisée à #9A42A4
  themeColor: any = '#A50000';
  // État d'activation/désactivation du bouton de paiement
  checkoutButton: any = true;

  // Indicateur de chargement pour le bouton de paiement
  checkoutButtonLoading: any = false;

  productPrice: number = 37; // Simulateur prix initial du produit
  dailyCustomers: number = 5; // Simulateur nombre initial de clients par jour
  projectedRevenue: number = 5550; // Simulateur revenu mensuel calculé


  items = Array.from({ length: 10 }, (_, i) => `Item ${i + 1}`);
  instances = Array.from({ length: 3 }); // 3 conteneurs par exemple
  positions: number[] = [];
  animationFrames: number[] = [];

  // Prix actuel du produit
  currentPrice: number = 0;

  // Prix initial du produit
  price: number = 97;

  // Prix promotionnel 1 après expiration du compte à rebours
  pricePromo01: number = 27;

  // Prix promotionnel 2 pendant le compte à rebours
  pricePromo02: number = 97;
  // Prix promotionnel 2 pendant le compte à rebours
  pricePromo03: number = 147;



  constructor(private router: Router, private ebookService: EbookService, private facebookService: FacebookService) { }

  ngOnInit(): void {

    this.loadExternalScript('https://fast.wistia.com/embed/medias/9c0h350k5y.jsonp');
    this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');

    // Initialisation des états du bouton de paiement
    this.checkoutButton = true;
    this.checkoutButtonLoading = false;
    this.currentPrice = this.pricePromo01;

    // Initialisation et suivi des événements Facebook
    this.facebookService.init(environment.products.ebooks.ebook02.pixel);
    this.facebookService.track('PageView');
    this.facebookService.sendEventToFacebook(environment.products.ebooks.ebook02.pixel, environment.products.ebooks.ebook02.accessToken, 'PageView');

  }

  updateRevenue(): void {
    // Calcul du revenu mensuel : prix produit * clients quotidiens * 30 jours
    this.projectedRevenue = this.productPrice * this.dailyCustomers * 30;
    this.updateSliderBackground('price', this.productPrice, 10, 500); // Ajustez min et max
    this.updateSliderBackground('daily-customers', this.dailyCustomers, 1, 100); // Ajustez min et max
  }

  updateSliderBackground(id: string, value: number, min: number, max: number): void {
    const slider = document.getElementById(id) as HTMLInputElement;
    const percentage = ((value - min) / (max - min)) * 100;
    slider.style.setProperty('--value', `${percentage}%`);
  }

  /**
     * Méthode pour faire défiler la page jusqu'à un élément spécifique.
     * @param elementId - ID de l'élément cible.
     */
  scrollToElement(elementId: string): void {
    const element = document.querySelector(`#${elementId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  ngAfterViewInit(): void {
    this.scrollTracks.forEach((track, index) => {
      this.positions[index] = 0; // Initialiser la position de chaque instance
      this.initializeScroll(track.nativeElement, index);
    });
      // Mettre à jour la couleur des sliders dès le chargement
      this.updateSliderBackground('price', this.productPrice, 10, 500);
      this.updateSliderBackground('daily-customers', this.dailyCustomers, 1, 100);
  }

  initializeScroll(track: HTMLElement, index: number): void {
    const items = Array.from(track.children) as HTMLElement[];

    // Dupliquer les éléments pour un défilement fluide
    items.forEach((item) => {
      const clone = item.cloneNode(true) as HTMLElement;
      track.appendChild(clone);
    });

    const totalWidth = Array.from(track.children).reduce(
      (acc, item: any) => acc + item.offsetWidth + 20, // 20 = gap
      0
    );

    track.style.width = `${totalWidth}px`;

    this.startScroll(track, index);
  }

  startScroll(track: HTMLElement, index: number): void {
    const scroll = () => {
      this.positions[index] -= 4; // Ajuster la vitesse pour chaque instance
      if (Math.abs(this.positions[index]) >= track.scrollWidth / 2) {
        // Remettre à zéro à mi-parcours
        this.positions[index] = 0;
      }
      track.style.transform = `translateX(${this.positions[index]}px)`;
      this.animationFrames[index] = requestAnimationFrame(scroll);
    };

    scroll();
  }

  ngOnDestroy(): void {
    this.animationFrames.forEach((frame) => cancelAnimationFrame(frame));
  }


  checkout(ref: any) {
    // Assignation du prix actuel à l'environnement du produit
    // environment.products.ebooks.ebook02.price = this.currentPrice;
    this.checkoutButton = false;
    this.checkoutButtonLoading = true;

    if (ref === 'starter') {
      // refOffer = 'le-guide-ultime';
      environment.products.ebooks.ebook02.ref = 'le-guide-ultime';
      environment.products.ebooks.ebook02.price = 27;
    } else {
      // refOffer = 'le-guide-ultime-p';
      environment.products.ebooks.ebook02.ref = 'le-guide-ultime-p';
      environment.products.ebooks.ebook02.price = 197;
    }

    let data = {
      id: environment.products.ebooks.ebook02.id,
      ref: environment.products.ebooks.ebook02.ref,
      price: environment.products.ebooks.ebook02.price,
      product: environment.products.ebooks.ebook02,
      pixel: environment.products.ebooks.ebook02.pixel,
      accessToken: environment.products.ebooks.ebook02.accessToken,
      themeColor: this.themeColor,
      checkoutCancelUrl: environment.ebookCheckoutCancelUrl,
      checkoutSuccessUrl: environment.ebookCheckoutSuccessUrl,
      stripe: environment.stripe
    }

    // Suivi des événements Facebook pour 'AddToCart'
    this.facebookService.init(environment.products.ebooks.ebook02.pixel);
    this.facebookService.track('AddToCart', {
      content_name: environment.products.ebooks.ebook02.ref,
      content_ids: environment.products.ebooks.ebook02.id,
      content_type: 'product',
      value: environment.products.ebooks.ebook02.price,
      currency: environment.products.ebooks.ebook02.devise,
      quantity: 1
    });

    let cartObject = {
      content_name: environment.products.ebooks.ebook02.ref,
      content_ids: environment.products.ebooks.ebook02.id,
      content_type: 'product',
      value: environment.products.ebooks.ebook02.price,
      currency: environment.products.ebooks.ebook02.devise,
      quantity: 1
    }

    this.facebookService.sendEventToFacebook(
      environment.products.ebooks.ebook02.pixel,
      environment.products.ebooks.ebook02.accessToken,
      'AddToCart',
      '',
      cartObject
    );

    // Appel au service de checkout pour redirection vers Stripe
    this.ebookService.checkout(data).subscribe({
      next: (response: any) => {
        /* success callback */
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        window.location.href = response.session.url;
      },
      error: (error: any) => {
        console.log(error);
        /* error callback */
        this.error = true;
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        setTimeout(() => {
          this.error = null;
        }, 3500); // 500 millisecondes = 0.5 seconde       
      }
    });
  }

  /**
  * Charge un script externe dans la page.
  * @param url - URL du script à charger.
  */
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }



}
