<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>
<section class="pt-5">
    <div class="container center pt-1">
        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
        <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
            Quel est ton budget pour te lancer ?
        </h3>
        <!-- Contenu visible uniquement sur les smartphones -->
        <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
            Quel est ton budget pour te lancer ?
        </h3>
    </div>
</section>
<section>
    <div class="container center">
        <div class="row mx-auto dotted-bg" style="width: 740px">
            <div class="col-md-12">
                <div class="card shadow-lg"
                    style="border-radius: 20px; background-color: #ffffff; border-radius: 20px; border-style: solid; border-color: rgb(255, 255, 255);">
                    <div class="card-body p-5">
                        <img src="assets/images/ebooks/ebook05/budget-import.png" width="60">
                        <hr>
                        <div class="input-group justify-content-center align-items-center mb-4" style="display: flex;">
                            <button (click)="minusBudget()" class="btn btn-circle btn-xl btn-decrement" type="button"
                                style="background-color: #FFD600; color: white; margin-right: -35px; height: 80px;">
                                <i class="fa-solid fa-minus"></i>
                            </button>
                            <div class="input-icon input-icon-right">
                                <input (keypress)="numberOnly($event)" (input)="validateBudgetInput($event)" type="text"
                                    class="form-control" [(ngModel)]="session.budget"
                                    style="font-size:50px; background-color: rgb(239, 238, 238); color: #061a35; text-align: center; padding: 10px;"
                                    [value]="formatNumber(session.budget)" placeholder="500" />
                                <i class="fa-solid fa-euro-sign"
                                    style="color: #061a35; position: absolute; right: 25px; top: 50%; transform: translateY(-50%);"></i>
                            </div>
                            <button (click)="plusBudget()" class="btn btn-circle btn-xl btn-increment" type="button"
                                style="background-color: #FFD600; color: white; margin-left: -35px; height: 80px;">
                                <i class="fa-solid fa-plus"></i>
                            </button>
                        </div>
                        <a (click)="validationBudget()"
                            class="button button-small button-circle button-green fw-bolder"><i
                                class="bi-arrow-down-right-circle-fill"></i>Etapes
                            suivantes</a>
                        <!-- <p class="fw-bolder" style="color: #ff0000;" *ngIf="errorBudget === true">
                            Aie !!! Pour nous le minumum d'investiseement c'est 500€
                        </p> -->
                        <p class="fw-bolder" style="color: #ff0000; min-height: 1.5em;"
                            [style.visibility]="errorBudget ? 'visible' : 'hidden'">
                            Aie !!! Pour nous le minumum d'investissement c'est 500€
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>








<app-simulator-footer></app-simulator-footer>