import { Component } from '@angular/core';
import { jwtDecode } from "jwt-decode";
// import * as jwt from 'jsonwebtoken';
import { environment } from '../../../environments/environment';
import { SimulatorService } from '../../services/simulator.service';
import { EbookService } from '../../services/ebook.service';
import { ActivatedRoute } from '@angular/router';
import { FacebookService } from '../../services/facebook.service';

@Component({
  selector: 'app-success-sale-ebook',
  templateUrl: './success-sale-ebook.component.html',
  styleUrls: ['./success-sale-ebook.component.css']
})
export class SuccessSaleEbookComponent {

  expired: any;
  tokenValid: any = true;
  errorToken: any;
  error: any;
  refEbook: any;
  themeColor: any;

  downloadButton: any = true;
  downloadButtonLoading: any = false;

  constructor(private route: ActivatedRoute, private ebookService: EbookService, private facebookService: FacebookService) { }

  ngOnInit() {
    const token: any = this.route.snapshot.paramMap.get('id');
    this.decodeToken(token);
  }

  decodeToken(token: any): any {
    try {
      const decodedToken: any = jwtDecode(token);
      this.refEbook = decodedToken.ref;      
      this.themeColor = decodedToken.themeColor;

      const expirationTimestamp: number = decodedToken.exp * 1000; // Convertir la date d'expiration en millisecondes
      const currentTimestamp: number = Date.now(); // Obtenir le timestamp actuel en millisecondes
      if (currentTimestamp > expirationTimestamp) {
        this.expired = true;
        this.tokenValid = false;
        this.errorToken = false;
        this.error = false;

      } else {
        this.tokenValid = true;
        this.expired = false;
        this.errorToken = false;
        this.error = false;

              
        let purchaseObject = {
          content_name: decodedToken.ref,
          content_ids: decodedToken.id,
          content_type: 'product',
          value: decodedToken.price,
          currency: 'EUR',
          quantity: 1
        }

        let pixel = decodedToken.pixel;
        let accessToken = decodedToken.accessToken;
        this.facebookService.init(pixel);
        this.facebookService.track('Purchase', purchaseObject);
        this.facebookService.sendEventToFacebook(pixel, accessToken, 'Purchase', '', purchaseObject);
        console.log('here purchase');
      
      }
    } catch (error) {
      this.errorToken = true;
      this.tokenValid = false;
      this.expired = false;
      this.error = false;

    }
  }


  getEbookFile() {
    this.downloadButton = false;
    this.downloadButtonLoading = true;
    this.ebookService.getEbookFile(this.refEbook).subscribe({
      next: (response: any) => {

        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = this.refEbook + '.pdf'; // Nom du fichier à télécharger
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadButton = true;
        this.downloadButtonLoading = false;
      },
      error: (error) => {
        this.error = true;
        this.tokenValid = true;
        this.errorToken = false;
        this.expired = false;
        this.downloadButton = true;
        this.downloadButtonLoading = false;

        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    });
  }



}
