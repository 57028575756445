import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
// Enregistrer le locale français
registerLocaleData(localeFr, 'fr');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SimulatorComponent } from './simulator/simulator.component';
import { SimulatorFooterComponent } from './simulator/simulator-footer/simulator-footer.component';
import { SimulatorHeaderComponent } from './simulator/simulator-header/simulator-header.component';
import { Step01Component } from './simulator/step01/step01.component';
import { Step02Component } from './simulator/step02/step02.component';
import { Step03Component } from './simulator/step03/step03.component';
import { Step04Component } from './simulator/step04/step04.component';
import { Step05Component } from './simulator/step05/step05.component';
import { Step06Component } from './simulator/step06/step06.component';
import { Step07Component } from './simulator/step07/step07.component';
import { Step08Component } from './simulator/step08/step08.component';
import { StepEmailComponent } from './simulator/step-email/step-email.component';
import { LoadReportComponent } from './simulator/load-report/load-report.component';
import { ResumeComponent } from './simulator/resume/resume.component';
import { SuccessSaleComponent } from './simulator/success-sale/success-sale.component';

import { ProductsEbookComponent } from './products-ebook/products-ebook.component';
import { ProductEbook01Component } from './products-ebook/product-ebook01/product-ebook01.component';
import { ProductEbook02Component } from './products-ebook/product-ebook02/product-ebook02.component';
import { ProductEbook03Component } from './products-ebook/product-ebook03/product-ebook03.component';
import { ProductEbook04Component } from './products-ebook/product-ebook04/product-ebook04.component';
import { ProductEbook05Component } from './products-ebook/product-ebook05/product-ebook05.component';
import { Ebook05Step00Component } from './products-ebook/product-ebook05/ebook05-step00/ebook05-step00.component';
import { Ebook05Step01Component } from './products-ebook/product-ebook05/ebook05-step01/ebook05-step01.component';
import { Ebook05Step02Component } from './products-ebook/product-ebook05/ebook05-step02/ebook05-step02.component';
import { Ebook05Step03Component } from './products-ebook/product-ebook05/ebook05-step03/ebook05-step03.component';
import { Ebook05Step04Component } from './products-ebook/product-ebook05/ebook05-step04/ebook05-step04.component';
import { Ebook05Step05Component } from './products-ebook/product-ebook05/ebook05-step05/ebook05-step05.component';
import { Ebook05CartComponent } from './products-ebook/product-ebook05/ebook05-cart/ebook05-cart.component';

import { ProductEbook06Component } from './products-ebook/product-ebook06/product-ebook06.component';

import { SuccessSaleEbookComponent } from './products-ebook/success-sale-ebook/success-sale-ebook.component';
import { CancelSaleEbookComponent } from './products-ebook/cancel-sale-ebook/cancel-sale-ebook.component';

import { TermsComponent } from './terms/terms.component';

/**
 * 
 */
import { ProductsSimulatorComponent } from './products-simulator/products-simulator.component';
import { Bu01Step00Component } from './products-simulator/simulator-business-01/bu-01-step00/bu-01-step00.component';
import { Bu01Step01Component } from './products-simulator/simulator-business-01/bu-01-step01/bu-01-step01.component';
import { Bu01Step02Component } from './products-simulator/simulator-business-01/bu-01-step02/bu-01-step02.component';
import { Bu01Step03Component } from './products-simulator/simulator-business-01/bu-01-step03/bu-01-step03.component';
import { Bu01Step04Component } from './products-simulator/simulator-business-01/bu-01-step04/bu-01-step04.component';
import { Bu01Step05Component } from './products-simulator/simulator-business-01/bu-01-step05/bu-01-step05.component';
import { Bu01Step06Component } from './products-simulator/simulator-business-01/bu-01-step06/bu-01-step06.component';
import { Bu01Step07Component } from './products-simulator/simulator-business-01/bu-01-step07/bu-01-step07.component';
import { Bu01Step08Component } from './products-simulator/simulator-business-01/bu-01-step08/bu-01-step08.component';
import { Bu01Step09Component } from './products-simulator/simulator-business-01/bu-01-step09/bu-01-step09.component';
import { Bu01Step10Component } from './products-simulator/simulator-business-01/bu-01-step10/bu-01-step10.component';
import { Bu01StepEmailComponent } from './products-simulator/simulator-business-01/bu-01-step-email/bu-01-step-email.component';
import { Bu01LoadingComponent } from './products-simulator/simulator-business-01/bu-01-loading/bu-01-loading.component';
import { Bu01CartComponent } from './products-simulator/simulator-business-01/bu-01-cart/bu-01-cart.component';
import { CancelSaleSimulatorComponent } from './products-simulator/cancel-sale-simulator/cancel-sale-simulator.component';
import { Bu01SucessSaleComponent } from './products-simulator/simulator-business-01/bu-01-sucess-sale/bu-01-sucess-sale.component';
import { CestnowProductsComponent } from './cestnow-products/cestnow-products.component';





// import { Ebook05Step00Component } from './products-ebook/product-ebook05/ebook05-step00/ebook05-step00.component';







@NgModule({
  declarations: [
    AppComponent,    
    SimulatorComponent,   
    SimulatorHeaderComponent,
    SimulatorFooterComponent,
    Step01Component,
    Step02Component,
    Step03Component,
    Step04Component,
    Step05Component,
    Step06Component,
    Step07Component,
    Step08Component,
    StepEmailComponent,   
    LoadReportComponent,
    ResumeComponent,
    SuccessSaleComponent,
    ProductsEbookComponent,
    SuccessSaleEbookComponent,
    CancelSaleEbookComponent,
    TermsComponent,
    ProductEbook01Component,
    ProductEbook02Component,
    ProductEbook03Component,
    ProductEbook04Component, 
    ProductEbook05Component,
    Ebook05Step00Component,
    Ebook05Step01Component,
    Ebook05Step02Component,
    Ebook05Step03Component,
    Ebook05Step04Component,    
    Ebook05Step05Component, 
    Ebook05CartComponent,   
    ProductsSimulatorComponent,
    Bu01Step00Component,
    Bu01Step01Component,
    Bu01Step02Component,
    Bu01Step03Component,
    Bu01Step04Component,
    Bu01Step05Component,
    Bu01Step06Component,
    Bu01Step07Component,
    Bu01Step08Component,
    Bu01Step09Component,
    Bu01Step10Component,
    Bu01StepEmailComponent,
    Bu01LoadingComponent,
    Bu01CartComponent,
    CancelSaleSimulatorComponent,
    Bu01SucessSaleComponent,
    CestnowProductsComponent,
    ProductEbook06Component,    
    // Ebook05Step00Component,  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule   
  
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' }, // Définit le local français
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' } // Définit l'euro par défaut
  ],
  bootstrap: [AppComponent]  
})
export class AppModule { }
