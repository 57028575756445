import { Component, ViewChild, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { Router } from "@angular/router";
import { Ebook05Session } from "../../../session/ebook05.session";
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-ebook05-cart',
  templateUrl: './ebook05-cart.component.html',
  styleUrls: ['./ebook05-cart.component.css']
})
export class Ebook05CartComponent {
  @ViewChildren('scrollTrack') scrollTracks!: QueryList<ElementRef>;

  // Session de simulation, pour stocker et récupérer les données de l'utilisateur
  session: any = {};
  // Gestion des erreurs
  error: any;
  // État d'activation/désactivation du bouton de paiement
  checkoutButton: any = true;
  // Indicateur de chargement pour le bouton de paiement
  checkoutButtonLoading: any = false;

  items = Array.from({ length: 10 }, (_, i) => `Item ${i + 1}`);
  instances = Array.from({ length: 3 }); // 3 conteneurs par exemple
  positions: number[] = [];
  animationFrames: number[] = [];


  product01: any = {
    title: 'Le kit Iphone',
    unitPrice: 8.50,  // Prix unitaire d'achat
    maxSellingPrice: 47, // Prix de vente
    minSellingPrice: 27, // Prix de vente
    sellingQuantity: 0, // Prix de vente
    profit: 0
  }

  product02: any = {
    title: 'Pneu de voiture',
    unitPrice: 27,  // Prix unitaire d'achat
    maxSellingPrice: 57, // Prix de vente
    minSellingPrice: 87, // Prix de vente
    sellingQuantity: 0, // Prix de vente
    profit: 0
  }
  product03: any = {
    title: 'Couverture',
    unitPrice: 10.50,  // Prix unitaire d'achat
    maxSellingPrice: 17, // Prix de vente
    minSellingPrice: 25, // Prix de vente
    sellingQuantity: 0, // Prix de vente
    profit: 0
  }



  constructor(private ebook05Session: Ebook05Session, private router: Router) {
  }

  ngOnInit(): void {
    // Récupère la session utilisateur
    this.session = this.ebook05Session.getItem(environment.products.ebooks.ebook05.sessionId);
    console.log(this.session);
    // Si la session est nulle ou si le produit n'a pas été sélectionné, redirige vers l'étape précédente
    if (this.session === null || this.session.step3.check === false) {
      this.router.navigateByUrl('/ebook/guide-import-export');
    }

    // // Initialise le pixel Facebook pour le suivi des événements
    // this.facebookService.init(environment.products.simulatorbu01.pixel);
    this.calculateProduct();
  }

  /**
 * Méthode pour faire défiler la page jusqu'à un élément spécifique.
 * @param elementId - ID de l'élément cible.
 */
  scrollToElement(elementId: string): void {
    const element = document.querySelector(`#${elementId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngAfterViewInit(): void {
    this.scrollTracks.forEach((track, index) => {
      this.positions[index] = 0; // Initialiser la position de chaque instance
      this.initializeScroll(track.nativeElement, index);
    });
  }

  initializeScroll(track: HTMLElement, index: number): void {
    const items = Array.from(track.children) as HTMLElement[];

    // Dupliquer les éléments pour un défilement fluide
    items.forEach((item) => {
      const clone = item.cloneNode(true) as HTMLElement;
      track.appendChild(clone);
    });

    const totalWidth = Array.from(track.children).reduce(
      (acc, item: any) => acc + item.offsetWidth + 20, // 20 = gap
      0
    );

    track.style.width = `${totalWidth}px`;

    this.startScroll(track, index);
  }

  startScroll(track: HTMLElement, index: number): void {
    const scroll = () => {
      this.positions[index] -= 4; // Ajuster la vitesse pour chaque instance
      if (Math.abs(this.positions[index]) >= track.scrollWidth / 2) {
        // Remettre à zéro à mi-parcours
        this.positions[index] = 0;
      }
      track.style.transform = `translateX(${this.positions[index]}px)`;
      this.animationFrames[index] = requestAnimationFrame(scroll);
    };

    scroll();
  }

  ngOnDestroy(): void {
    this.animationFrames.forEach((frame) => cancelAnimationFrame(frame));
  }


  /**
  * Gère le processus de checkout lorsque l'utilisateur ajoute un produit au panier.
  * @param ref - La ref du produit sélectionné.
  */
  checkout(ref: any) {

  }

  calculateProduct() {
    // Calculer produit 01 
    // this.product01.sellingQuantity = this.session.budget / this.product01.unitPrice;    
    this.product01.sellingQuantity = Math.floor(this.session.budget / this.product01.unitPrice) - 1;

    this.product02.sellingQuantity = Math.floor(this.session.budget / this.product02.unitPrice) - 1;
  
    this.product03.sellingQuantity = Math.floor(this.session.budget / this.product03.unitPrice) - 1;

    // console.log(this.product01.sellingQuantity);

    // Calculer la marge
    // this.product01.profit = this.product01.sellingQuantity * this.product01.sellingPrice;
    // this.product01.profit = this.product01.sellingQuantity / this.product01.minSellingPrice;

    // console.log(this.product01.sellingQuantity);
    // console.log(this.product01.profit);


    // // Calculer produit 01 
    // this.product01.sellingQuantity = Math.floor(this.session.budget / this.product01.unitPrice) - 1;

    // Calculer la marge (profit)
    // this.product01.profit = (this.product01.sellingQuantity * this.product01.minSellingPrice) - (this.product01.sellingQuantity * this.product01.unitPrice);
    this.product01.profit = (this.product01.sellingQuantity * this.product01.minSellingPrice) ;
    this.product02.profit = (this.product02.sellingQuantity * this.product02.minSellingPrice) ;
    this.product03.profit = (this.product03.sellingQuantity * this.product03.minSellingPrice) ;

    console.log('Quantité vendue :', this.product01.sellingQuantity);
    console.log('Profit généré :', this.product01.profit);

  }


}
