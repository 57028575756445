<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>


<section id="slider" class="slider-element  scroll-detect include-header bg-contrast-100 scroll-detect-inview "
    style=" margin-bottom: -50px;">
    <div class="py-6 mt-xl-6 position-relative z-2">
        <div class="container">
            <div class="row align-items-center justify-content-center col-mb-80 mb-0 section-video-vsl"
                style="padding-bottom: -90px;">
                <div class="col-12 text-center pt-0 pb-0 title-sale-page">
                    <h1 class="mb-4 display-4">
                        <span class="mb-4 fw-bolder text-black">Le secret des pros pour une déco
                            réussie… à portée de main !</span>
                    </h1>
                    <div class="wistia_responsive_padding mb-0" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_jujdckh9s0 seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/jujdckh9s0/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <span class="pt-0 mb-1 fw-bolder d-none d-md-block" style="color: red;"><i style="color: red;"
                            class="uil-volume-up tada slower infinite animated h1"></i> Assurez-toi que le son est
                        activé.</span>
                </div>
                <div class="text-center pt-3 mb-4">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
                <div class="col-12 col-lg-10 center">
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class="row d-block d-md-none pt-4">
                        <div class="col-lg-12">
                            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                            <h6 class="display-5 fw-bolder ls1 mb-5 position-relative d-none d-md-block text-black">
                                Vous avez plein d’idées pour votre intérieur, mais rien ne s’assemble
                                comme vous le souhaitez ?
                            </h6>
                            <!-- Contenu visible uniquement sur les smartphones -->
                            <h6 class="display-6 fw-bolder ls1 mb-5 position-relative d-block d-md-none text-black">
                                Vous avez plein d’idées pour votre intérieur, mais rien ne s’assemble
                                comme vous le souhaitez ?
                            </h6>
                            <ul class="iconlist iconlist-lg" style="--cnvs-iconlist-offset:1px;">
                                <li><i class="bi-check-circle-fill color-blue"></i> <span style="text-align: left;">Vous
                                        passez des heures à chercher l’inspiration
                                        sur Pinterest ou Instagram, mais tout reste flou.</span></li>
                                <li><i class="bi-check-circle-fill color-blue"></i> <span
                                        style="text-align: left;">Chaque décision – couleur, meuble, agencement –
                                        devient un casse-tête sans fin.</span></li>
                                <li><i class="bi-check-circle-fill color-blue"></i> <span
                                        style="text-align: left;">Résultat : des achats impulsifs, des choix mal
                                        assortis, et un intérieur qui manque de cohérence.</span></li>
                                <li><i class="bi-check-circle-fill color-blue"></i> <span style="text-align: left;">Vous
                                        vous sentez submergé(e), sans méthode claire
                                        pour avancer et concrétiser vos envies.</span></li>
                            </ul>
                        </div>
                    </div>
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="row d-none d-md-block">
                        <div class="col-lg-12">
                            <div
                                class="grid-inner d-flex flex-column justify-content-center border border-default rounded-4 p-5 h-100 shadow-lg">
                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <h6 class="display-5 fw-bolder ls1 mb-5 position-relative d-none d-md-block text-black">
                                    Vous avez plein d’idées pour votre intérieur, mais rien ne s’assemble
                                    comme vous le souhaitez ?
                                </h6>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <h6 class="display-6 fw-bolder ls1 mb-5 position-relative d-block d-md-none text-black">
                                    Vous avez plein d’idées pour votre intérieur, mais rien ne s’assemble
                                    comme vous le souhaitez ?
                                </h6>
                                <ul class="iconlist iconlist-lg" style="--cnvs-iconlist-offset:1px;">
                                    <li><i class="bi-check-circle-fill color-blue"></i> <span class="fw-bolder"
                                            style="text-align: left;">Vous passez des heures à chercher l’inspiration
                                            sur Pinterest ou Instagram, mais tout reste flou.</span></li>
                                    <li><i class="bi-check-circle-fill color-blue"></i> <span class="fw-bolder"
                                            style="text-align: left;">Chaque décision – couleur, meuble, agencement –
                                            devient un casse-tête sans fin.</span></li>
                                    <li><i class="bi-check-circle-fill color-blue"></i> <span class="fw-bolder"
                                            style="text-align: left;">Résultat : des achats impulsifs, des choix mal
                                            assortis, et un intérieur qui manque de cohérence.</span></li>
                                    <li><i class="bi-check-circle-fill color-blue"></i> <span class="fw-bolder"
                                            style="text-align: left;">Vous vous sentez submergé(e), sans méthode claire
                                            pour avancer et concrétiser vos envies.</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="position-absolute w-100 h-100 top-0 start-0 z-1 py-0">
        <div class="d-flex px-4 w-100 mt-6 pt-5">
            <div class="position-relative w-50 intro-hero-images-section">
                <div class="position-relative d-flex fadeInDownSmall animated" style="width:35%;left:1%;"
                    data-animate="fadeInDownSmall">
                    <!-- Yellow gauche 2eme heu -->
                    <img width="221" height="159" src="assets/images/ebooks/ebook06/color-card-y.jpg"
                        data-src="assets/images/ebooks/ebook06/color-card-y.jpg" alt="Classic Demo"
                        class="rounded lazy w-50 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <!-- beige  gauche 1er haut-->
                    <div class="position-absolute fadeInRightSmall animated" style="width:65%;right:-14.5%;top:-50%;"
                        data-animate="fadeInRightSmall" data-delay="550">
                        <img width="221" height="159" src="assets/images/ebooks/ebook06/color-carre-beige.jpg"
                            data-src="assets/images/ebooks/ebook06/color-carre-beige.jpg" alt="Speaker Demo"
                            class="rounded lazy ms-4 shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInLeft animated" style="width:37%;left:-18%;"
                    data-animate="fadeInLeft" data-delay="250">
                    <!-- Purple  gauche 4eme haut-->
                    <img width="221" height="159" src="assets/images/ebooks/ebook06/color-card-p.jpg"
                        data-src="assets/images/ebooks/ebook06/color-card-p.jpg" alt="Recipes Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute zoomIn animated" style="width: 50%;right: -57%;top: -9%;"
                        data-animate="zoomIn" data-delay="800">
                        <!-- beige  gauche 3eme haut-->
                        <img width="221" height="159" src="assets/images/ebooks/ebook06/color-carre-beige.jpg"
                            data-src="assets/images/ebooks/ebook06/color-carre-beige.jpg" alt="App Landing Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(var(--cnvs-scroll-end)*30%),calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                    <div class="position-absolute zoomIn animated" style="width: 48%;right: -55%;top: 51.5%;"
                        data-animate="zoomIn" data-delay="1000">
                        <img width="221" height="159" src="assets/images/ebooks/ebook06/color-card-pink.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Yoga Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(var(--cnvs-scroll-end)*50%),calc(var(--cnvs-scroll-end)*200%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInUp animated" style="width:38%;left: 3%;"
                    data-animate="fadeInUp" data-delay="500">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                        data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="Supplements Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                </div>
            </div>
            <div class="position-relative w-50 intro-hero-images-section d-flex flex-column align-items-end">
                <div class="position-relative d-flex fadeInDownSmall animated" style="width:35%;right:1%;"
                    data-animate="fadeInDownSmall">
                    <img width="221" height="159" src="assets/images/ebooks/ebook06/color-card-y.jpg"
                        data-src="assets/images/ebooks/ebook06/color-card-y.jpg" alt="Conference Demo"
                        class="rounded lazy w-50 shadow ms-auto entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute fadeInLeftSmall animated" style="width: 65%;left:-30%;top:-50%;"
                        data-animate="fadeInLeftSmall" data-delay="550">
                        <img width="221" height="159" src="assets/images/ebooks/ebook06/color-carre-beige.jpg"
                            data-src="assets/images/ebooks/ebook06/color-carre-beige.jpg" alt="Freelancer Demo"
                            class="rounded lazy shadow ms-4 w-100 entered lazy-loaded"
                            style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInRight animated" style="width:37%;right:-18%;"
                    data-animate="fadeInRight" data-delay="250">
                    <!-- Purple  droite 4eme haut-->
                    <img width="221" height="159" src="assets/images/ebooks/ebook06/color-card-p.jpg"
                        data-src="assets/images/ebooks/ebook06/color-card-p.jpg" alt="Cleaner Demo"
                        class="rounded lazy w-100 shadow ms-auto entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute zoomIn animated" style="width: 50%;left: -57%;top: -9%;"
                        data-animate="zoomIn" data-delay="800">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Furniture Demo"
                            class="rounded lazy w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(-1*var(--cnvs-scroll-end)*30%),calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                    <div class="position-absolute zoomIn animated" style="width: 48%;left: -55%;top: 51.5%;"
                        data-animate="zoomIn" data-delay="1000">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Skincare Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(-1*var(--cnvs-scroll-end)*50%),calc(var(--cnvs-scroll-end)*200%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInUp animated" style="width:38%;right: 3%;"
                    data-animate="fadeInUp" data-delay="500">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                        data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="SEO Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section-02-solution-title">
    <div class="py-5 text-center bg-contrast-100 border-top border-bottom border-contrast-200"
        data-class="dark:bg-contrast-200 dark:border-contrast-300" style="background-color: #1B6A97 !important;">
        <div class="container">


            <div class="text-center section-simulator-title">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-3 position-relative d-none d-md-block text-white pt-0">
                    Et si vous aviez enfin une solution pour organiser vos idées et transformer vos inspirations en
                    une déco harmonieuse
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none text-white pt-0">
                    Et si vous aviez enfin une solution pour organiser vos idées et transformer vos inspirations en
                    une déco harmonieuse
                </h6>
                <a (click)="scrollToElement('section-pricing')"
                    class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
            </div>

        </div>
    </div>
</section>

<section class="mockup-section">
    <div class="content-wrap">
        <div class="container">

            <div class="text-center mb-0 mockup-section-title">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    Vous rêvez d’un intérieur qui vous ressemble, mais vous ne savez pas par où commencer ?
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                    Vous rêvez d’un intérieur qui vous ressemble, mais vous ne savez pas par où commencer ?
                </h6>

                <div class="text-center mt-3 mb-5 pt-3">
                    <p class="lead"> Et si vous aviez enfin l’outil qu’il vous faut pour donner vie à vos idées, éviter
                        les
                        erreurs déco
                        coûteuses et transformer votre espace en un lieu qui vous inspire chaque jour ?
                        La planche d’ambiance, c’est bien plus qu’un simple collage d’images :
                        C’est votre plan d’action visuel pour créer une déco qui ne laisse rien au hasard.
                        Pourquoi elle va tout changer pour vous </p>
                </div>

            </div>

            <div class="row justify-content-center align-items-stretch g-4 mb-5">
                <div class="col-md-6 col-lg-4 col-xl-4">
                    <div class="grid-inner border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                        style="background-color: #1c6a98;" data-class="dark:bg-contrast-100">
                        <div class="d-flex fs-1 color mb-3">
                            <i class="bi-magic text-white"></i>
                        </div>
                        <h3 class="fs-5 mb-2 text-white">Un cap clair pour votre projet</h3>
                        <p class="mb-4 fw-light text-white">Fini les hésitations
                            ou les achats inutiles. Vous visualisez
                            exactement ce que vous voulez et comment l’obtenir.</p>

                        <div class="bg-icon bi-magic color" style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1">
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4 col-xl-4">
                    <div class="grid-inner border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                        style="background-color: #7A1F58;" data-class="dark:bg-contrast-100">
                        <div class="d-flex fs-1 color mb-3">
                            <i class="bi-palette text-white"></i>
                        </div>
                        <h3 class="fs-5 mb-2 text-white">Une harmonie garantie</h3>
                        <p class="mb-4 fw-light text-white">Couleurs, matières, styles... tout est pensé pour que votre
                            intérieur
                            soit à la fois beau et cohérent.</p>
                        <div class="bg-icon bi-palette color" style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1">
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-lg-4 col-xl-4">
                    <div class="grid-inner border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                        style="background-color: #F9C406;" data-class="dark:bg-contrast-100">
                        <div class="d-flex fs-1 color mb-3">
                            <i class="bi-ui-checks text-white"></i>
                        </div>
                        <h3 class="fs-5 mb-2 text-white">Des choix simples et efficaces</h3>
                        <p class="mb-4 fw-light text-white">Chaque décision devient facile à prendre, sans peur de vous
                            tromper.
                        </p>
                        <div class="bg-icon bi-shield-check color"
                            style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1"></div>
                    </div>
                </div>
            </div>

            <div class="text-center mt-3 mb-5">
                <p class="lead">Transformez vos inspirations en une réalité saisissante
                    Imaginez-vous parcourir votre espace fraîchement décoré, fier(e) du résultat, et surtout,
                    heureux(se) d’avoir enfin un intérieur à votre image.
                    Avec la planche d’ambiance, c’est exactement ce que vous allez accomplir. Vous ne regarderez plus
                    jamais votre déco comme avant.</p>
            </div>

            <div class="row gallery-categories gutter-20 mockup-section-gallery">
                <div class="col-md-12">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book.jpg') no-repeat center center; background-size: cover; height: 450px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book-01.jpg') no-repeat center center; background-size: cover; height: 250px;">
                    </div>
                </div>

                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book-03.jpg') no-repeat 50% 40%; background-size: cover; height: 250px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book-02.jpg') no-repeat 100% 100%; background-size: cover; height: 250px;">
                    </div>
                </div>
            </div>
            <div class="col-12 center pt-5">
                <a (click)="scrollToElement('section-pricing')"
                    class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
            </div>
        </div>

    </div>
</section>



<section class="section-programme">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-programme-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Ce que vous allez gagner avec ce Guide
                        <br />Voici ce qui t’attend :
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Ce que vous allez gagner avec ce Guide
                        <br />Voici ce qui t’attend :
                    </h6>
                </div>
                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #1b6a97;"
                            data-bs-theme="dark">
                            <h3 class="mb-3 display-6 fw-bold"><b>Une newsletter mensuelle inspirante pour t'inspirer
                                    dans ta tranformation interieur.</b></h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #ebcece;">
                            <h3 class="mb-0 display-7 fw-bold text-white"><b>Clarté dans vos idées
                                </b></h3>
                            <hr>
                            <p class="lead fw-bolder mb-0 text-white">Dites adieu à la confusion et aux doutes !
                                Découvrez votre style
                                unique et apprenez à créer une déco cohérente, en toute
                                sérénité.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #7b1f58;">
                            <!-- <img src="images/presentiel.png" data-animate="tada slower infinite" width="100px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-0 display-7 fw-bold text-white"><b>Une méthode simple et
                                    efficace </b>
                            </h3>
                            <hr>
                            <p class="lead fw-bolder mb-0 text-white">Un guide étape par étape qui vous accompagne du
                                rêve à la
                                réalité. Chaque étape est pensée pour être claire, accessible et
                                réalisable, même sans expérience en déco.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #77afa5;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-0 display-7 fw-bold text-white"><b>Des économies
                                    considérables </b>
                            </h3>
                            <hr>
                            <p class="lead mb-0 fw-bolder text-white">Stop aux erreurs coûteuses et aux choix impulsifs
                                ! Avec ce guide,
                                chaque décision est réfléchie, chaque euro dépensé est un
                                investissement dans votre vision.

                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg " data-bs-theme="dark">
                            <h3 class="mb-0 display-7 fw-bold"><b>Une confiance en vous retrouvée</b>
                            </h3>
                            <hr>
                            <p class="lead fw-bolder">Fini le stress des décisions déco ! Avec une vision claire et une
                                planche d’ambiance bien préparée, chaque choix devient naturel et
                                évident.
                            </p>
                            <!-- <img src="images/replay.png" data-animate="tada slower infinite" width="100px" class="center tada slower infinite animated" alt="Feature Icon"> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #f8c305;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-0 display-7 fw-bold text-white"><b>Un intérieur à votre
                                    image</b>
                            </h3>
                            <hr>
                            <p class="lead text-white fw-bolder">Transformez votre maison en un espace qui raconte VOTRE
                                histoire, où chaque détail reflète qui vous êtes vraiment.

                            </p>
                        </div>
                    </div>

                </div>
                <div class="row justify-content col-mb-50 align-items-stretch pt-5">


                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #F4F1EC;">
                            <h3 class="mb-3 display-7 fw-bold"><b>Accessible à tous</b>
                            </h3>
                            <hr>
                            <p class="lead mb-0 fw-bolder">Pas besoin d’être un(e) expert(e) ! Ce guide est conçu pour
                                que
                                tout le monde puisse réussir sa déco, même sans connaissance
                                préalable.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section>
    <div class="py-5 text-center bg-contrast-100 border-top border-bottom border-contrast-200"
        data-class="dark:bg-contrast-200 dark:border-contrast-300" style="background-color: #1B6A97 !important;">
        <div class="container">
            <h2 class="mb-0 text-white"><strong>Guide complet + Templates exclusifs + Checklist +
                    Newsletter à vie</strong>
            </h2>
            <div class="pt-3">
                <a (click)="scrollToElement('section-pricing')"
                    class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">

        <div class="text-center section-programme-title pt-6">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                Une newsletter pour nourrir votre créativité
                déco
            </h6>
            <!-- Contenu visible uniquement sur les smartphones -->
            <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                Une newsletter pour nourrir votre créativité
                déco
            </h6>
            <p>Rejoignez ma newsletter et recevez, chaque mois, une dose
                d’inspiration pour transformer votre intérieur </p>
        </div>
        <div class="row justify-content-between align-items-center">
            <div class="col-md-5">
                <ul class="swiper-links-container list-unstyled">
                    <li class="swiper-tab-link active">
                        <h3 class="swiper-tab-title">Idées exclusives</h3>
                        <p class="swiper-tab-content"> Des astuces déco inédites pour vous aider
                            à personnaliser chaque recoin de votre maison.</p>
                        <div class="swiper-pagination-progress">
                            <span class="swiper-pagination-bar"></span><span
                                class="swiper-pagination-bar-active"></span>
                        </div>
                    </li>
                    <li class="swiper-tab-link">
                        <h3 class="swiper-tab-title">
                            Tendances du moment</h3>
                        <p class="swiper-tab-content">Découvrez les styles et matériaux
                            qui font sensation, et comment les intégrer chez vous.</p>
                        <div class="swiper-pagination-progress">
                            <span class="swiper-pagination-bar"></span><span
                                class="swiper-pagination-bar-active"></span>
                        </div>
                    </li>
                    <li class="swiper-tab-link">
                        <h3 class="swiper-tab-title">Conseils pratiques</h3>
                        <p class="swiper-tab-content">Des mini-guides pour éviter les erreurs
                            courantes et maximiser l’impact de vos projets déco.
                        </p>
                        <div class="swiper-pagination-progress">
                            <span class="swiper-pagination-bar"></span><span
                                class="swiper-pagination-bar-active"></span>
                        </div>
                    </li>
                    <li class="swiper-tab-link">
                        <h3 class="swiper-tab-title">Zoom sur les détails</h3>
                        <p class="swiper-tab-content">Parce que ce sont les petites touches
                            qui rendent une maison unique et mémorable.
                        </p>
                        <div class="swiper-pagination-progress">
                            <span class="swiper-pagination-bar"></span><span
                                class="swiper-pagination-bar-active"></span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <div id="swiper-tab" class="swiper_wrapper h-auto">
                    <div class="swiper swiper-tab-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <img src="assets/images/ebooks/ebook06/1-idee-exclusives.jpg" alt=".." class="w-100"
                                    style="border-radius: 30px;">
                            </div>
                            <div class="swiper-slide">
                                <img src="assets/images/ebooks/ebook06/2-tendance-du-moment.jpg" alt=".."
                                    class="w-100 bg-success bg-opacity-10" style="border-radius: 30px;">
                            </div>
                            <div class="swiper-slide">
                                <img src="assets/images/ebooks/ebook06/3-conseil-pratique.jpg" alt=".." class="w-100 "
                                    style="border-radius: 30px;">
                            </div>
                            <div class="swiper-slide">
                                <img src="assets/images/ebooks/ebook06/4-zoom-details.jpg" alt=".." class="w-100 "
                                    style="border-radius: 30px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-6">
    <div class="py-0 text-center bg-contrast-100 border-top border-bottom border-contrast-200"
        data-class="dark:bg-contrast-200 dark:border-contrast-300" style="background-color: #7B1F58 !important;">
        <div class="container">
            <div class="text-center mb-0 pt-5">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-0 position-relative d-none d-md-block text-white mb-5">
                    On te présente des planches d'ambiance
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-5 position-relative d-block d-md-none text-white">
                    On te présente des planches d'ambiance
                </h6>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
        <img src="assets/images/ebooks/ebook06/planche-d-ambiance.jpg" class="mb-4 pt-5 d-none d-md-block">
        <!-- Contenu visible uniquement sur les smartphones -->
        <img src="assets/images/ebooks/ebook06/planche-anbiance-mobile.jpg" class="mb-4 pt-5 d-block d-md-none">
        <!-- Contenu visible uniquement sur les smartphones -->
        <div class="row align-items-stretch justify-content-center center mb-4 d-block d-md-none">
            <div class="col-12">
                <p class="lead">Une planche d’ambiance, c’est bien plus qu’un outil. C’est la clé pour
                    transformer tes idées en un projet déco clair et cohérent. Utilisée par
                    les professionnels, elle rassemble tous les éléments essentiels de ta
                    décoration pour devenir ta boussole déco, celle qui t’aide à prendre des
                    décisions réfléchies et inspirées avant tout achat ou travaux.
                </p>
            </div>
        </div>
        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
        <div class="row align-items-stretch justify-content-center center mb-6 d-none d-md-block">
            <div class="col-12">
                <span class="fw-bolder">Une planche d’ambiance, c’est bien plus qu’un outil. C’est la clé pour
                    transformer tes idées en un projet déco clair et cohérent. Utilisée par
                    les professionnels, elle rassemble tous les éléments essentiels de ta
                    décoration pour devenir ta boussole déco, celle qui t’aide à prendre des
                    décisions réfléchies et inspirées avant tout achat ou travaux.
                </span>
            </div>
        </div>
    </div>
</section>

<section class="gradient-section" style="background-color: #F4F1EC;">
    <div class="container">
        <div class="row justify-content-center">

            <div class="text-center section-programme-book-workbook-title pt-5">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    Pourquoi en avez-vous besoin ?
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                    Pourquoi en avez-vous besoin ?
                </h6>
            </div>

            <div class="col-xl-8 col-lg-8 text-center ">
                <p class="mb-5 pt-3  text-black display-7 lead">
                    Avec une planche d’ambiance, tu dis adieu aux erreurs coûteuses et aux
                    regrets. Voici ce qu’elle t’apporte :

                </p>
            </div>
            <div class="container">
                <div class="container">
                    <div class="row justify-content-center col-mb-50 align-items-stretch">
                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                            <div class="d-flex flex-column p-4  shadow-lg dark"
                                style="background-color: #1b6a97; border-radius: 40px;" data-bs-theme="dark">
                                <img src="assets/images/ebooks/ebook06/vision.png" data-animate="tada slower infinite"
                                    width="80px" alt="Feature Icon" class="tada slower infinite animated">
                                <h3 class="mb-3 display-6 fw-bold text-white"><b>Une vision claire : Plus de doutes ni
                                        d’hésitations, tu sais exactement
                                        où tu vas et pourquoi.
                                    </b>
                                </h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                            <div class="d-flex flex-column p-4 shadow-lg"
                                style="background-color: #77afa5; border-radius: 40px;">
                                <img src="assets/images/ebooks/ebook06/coche.png" data-animate="tada slower infinite"
                                    width="80px" alt="Feature Icon" class="mb-0 tada slower infinite animated">
                                <h3 class="mb-3 display-6 fw-bold text-black"><b>Des décisions réfléchies : Chaque choix
                                        s’intègre parfaitement dans
                                        une déco harmonieuse, sans incohérences.
                                    </b>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">

                    <div class="row justify-content-center col-mb-50 align-items-stretch pt-5 mb-5">

                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                            <div class="d-flex flex-column p-4 shadow-lg dark"
                                style="background-color: #ebcece; border-radius: 40px;" data-bs-theme="dark">
                                <img src="assets/images/ebooks/ebook06/money-management.png"
                                    data-animate="tada slower infinite" width="80px"
                                    class="center tada slower infinite animated" alt="Feature Icon">
                                <h3 class="mb-3 display-6 fw-bold text-black"><b>Des économies précieuses : Tu arrêtes
                                        de gaspiller temps et argent
                                        en achats impulsifs ou mal pensés.
                                    </b>
                                </h3>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                            <div (click)="scrollToElement('section-pricing')"
                                class="pointer d-flex flex-column p-4 shadow-lg"
                                style="background-color: #f8c305; border-radius: 40px;">
                                <img src="assets/images/ebooks/ebook06/navette.png" data-animate="tada slower infinite"
                                    width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated">
                                <h3 class="mb-3 display-6 fw-bold text-black"><b>Je passe à l’action avec "LE GUIDE
                                        PLANCHE D'AMBIANCE"</b></h3>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-stretch justify-content-center center mb-6">
                        <div class="col-12">
                            <p class="lead">Avant de peindre un mur ou d’acheter un meuble, ta planche
                                d’ambiance
                                devient ton alliée, te guidant vers un intérieur parfaitement aligné avec tes
                                goûts et tes besoins.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section-qui-je-suis">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-qui-je-suis-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-0 position-relative d-none d-md-block">
                        Je vous accompagne dans la
                        transformation de votre intérieur
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-0 position-relative d-block d-md-none">
                        Je vous accompagne dans la
                        transformation de votre intérieur
                    </h6>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block" style="margin-top: -63px;">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">Qui je suis ?</h2>
                    <p class="lead mb-5">Je suis Nadia, décoratrice d’intérieur et fondatrice d’Essika Design. Mon
                        métier ?
                        Donner vie à vos idées et transformer chaque espace en une véritable extension
                        de votre personnalité.
                    </p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/nadia-essika.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook06/color-card-y.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook06/color-card-y.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook06/essika-design-nadia.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile" style="margin-top: -120px;">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">Qui je suis ?</h2>
                    <p class="lead mb-0">Je suis Nadia, décoratrice d’intérieur et fondatrice d’Essika Design, une
                        entreprise où chaque projet est conçu pour transformer un espace en une véritable extension de
                        votre personnalité."</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/nadia-essika.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook06/color-card-y.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section style="margin-top: -80px;">
    <div class="content-wrap">
        <div class="container">
            <div class="heading-block mb-5 text-center border-bottom-0 pt-3">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h2 class="text-transform-none fw-bolder d-none d-md-block">
                    TÉMOIGNAGES
                </h2>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h3 class="text-transform-none fw-bolder d-block d-md-none">
                    TÉMOIGNAGES
                </h3>
            </div>

            <div class="row user-testimonials">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Je pensais que faire appel à un décorateur était la
                                seule solution pour
                                réussir ma déco. Mais avec ce guide, j’ai appris à tout faire moi-même, étape
                                par étape, sans erreurs coûteuses. C’est une vraie économie, et le résultat est
                                bluffant !"</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Amélie D.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Je n’avais aucune idée de par où commencer pour ma
                                déco. Ce guide m’a
                                appris les bases et m’a guidée avec des étapes simples et faciles à suivre.
                                Même en étant novice, j’ai réussi à créer un intérieur qui me correspond
                                parfaitement."
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Nadia K.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"J’étais complètement perdue avec toutes les options
                                et styles disponibles. Ce
                                guide m’a donné une méthode claire pour organiser mes idées et passer à
                                l’action. Le résultat ? Une déco harmonieuse et un sentiment
                                d’accomplissement."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Julie T.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"J"’avais toujours rêvé d’un intérieur qui reflète ma
                                personnalité, mais je ne
                                savais pas par où commencer. Grâce à ce guide, j’ai pu clarifier mes idées et
                                créer une planche d’ambiance qui m’a guidée dans chaque décision.
                                Aujourd’hui, ma maison me ressemble vraiment !"</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sarah M.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Grâce à ce guide, j’ai enfin réussi à organiser mes
                                idées et à donner vie à un
                                intérieur qui me ressemble. Tout est expliqué de manière simple et claire,
                                même pour une débutante comme moi. Résultat : ma maison est enfin
                                harmonieuse !"</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sophie R.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Je passais des heures à chercher des idées sans
                                savoir comment les
                                appliquer. Ce guide a été une révélation : une méthode pas à pas qui m’a
                                aidée à structurer mon projet déco. Plus d’hésitations, et un gain de temps
                                incroyable !"</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sophia J.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-5">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/guide-pdf-03.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook06/guide-pdf-02.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">📘 Un guide complet en PDF</h2>
                    <p class="lead mb-5">Une méthode étape par étape, claire et accessible, pour structurer vos
                        idées et concevoir une déco cohérente, même si vous n’avez aucune
                        expérience.</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile" style="margin-top: -120px;">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">📘 Un guide complet en PDF</h2>
                    <p class="lead mb-0">Un outil essentiel qui vous aide à clarifier vos goûts et à définir une
                        décoration qui vous correspond parfaitement. Ce questionnaire est
                        comme un révélateur de votre identité déco.</p>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/guide-pdf-01.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook06/guide-pdf-03.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">📄 Un questionnaire exclusif pour découvrir votre style</h2>
                    <p class="lead mb-5">Un outil essentiel qui vous aide à clarifier vos goûts et à définir une
                        décoration qui vous correspond parfaitement. Ce questionnaire est
                        comme un révélateur de votre identité déco</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/questionnaire-01.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook06/questionnaire-02.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile" style="margin-top: -120px;">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">📄 Un questionnaire exclusif pour découvrir votre style</h2>
                    <p class="lead mb-0">Un outil essentiel qui vous aide à clarifier vos goûts et à définir une
                        décoration qui vous correspond parfaitement. Ce questionnaire est
                        comme un révélateur de votre identité déco.
                    </p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/questionnaire-01.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook06/questionnaire-02.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/illustration-01.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook06/illustration-02.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">🎨 Des illustrations inspirantes et des exemples concrets</h2>
                    <p class="lead mb-5">Des visuels clairs pour vous montrer comment construire votre planche
                        d’ambiance et passer de l’inspiration à la réalisation.</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile" style="margin-top: -120px;">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">🎨 Des illustrations inspirantes et des exemples concrets</h2>
                    <p class="lead mb-0">Des visuels clairs pour vous montrer comment construire votre planche
                        d’ambiance et passer de l’inspiration à la réalisation.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/illustration-01.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook06/illustration-02.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">✅ Une méthode testée et approuvé</h2>
                    <p class="lead mb-5">Pas de théorie compliquée, uniquement des étapes simples et efficaces
                        que j’ai utilisées avec succès auprès de mes clients.</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/methode-testee-01.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook06/methode-testee-02.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile" style="margin-top: -120px;">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <!-- <div class="text-uppercase ls-2 mb-4 op-04">Template</div> -->
                    <h2 class="display-4 mb-4 fw-bolder">✅ Une méthode testée et approuvé</h2>
                    <p class="lead mb-0">Pas de théorie compliquée, uniquement des étapes simples et efficaces
                        que j’ai utilisées avec succès auprès de mes clients.
                    </p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook06/methode-testee-01.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook06/methode-testee-02.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Desktop version -->
<section id="section-pricing" class="section-price">
    <div class="content-wrap">
        <div class="container">

            <div class="row justify-content-between">

                <div class="col-xl-4 mb-5 mb-xl-0">
                    <div class="row mx-0 h-100 rounded-5 p-5" style="background-color: #1B6A97">
                        <div class="col-12 col-lg-12 col-xl-12">
                            <h3 class="h3 mb-4 text-white fw-bolder">Télécharger le guide deco
                            </h3>
                            <p class="text-white">Apprenez à transformer votre intérieur en un
                                espace unique qui reflète vos goûts et votre
                                personnalité, étape par étape.</p>
                        </div>
                        <img src="assets/images/ebooks/ebook06/book-05.jpg" alt="Image"
                            class="mt-xl-auto col-12 col-lg-4 col-xl-12">
                    </div>
                </div>

                <div class="col-xl-8 d-flex flex-column justify-content-end">
                    <div class="row mb-2">
                        <div class="col-md-8">
                            <h3 class="h1">Une solution
                                <br>accessible à tous !
                            </h3>
                        </div>

                    </div>
                    <div class="row pricing">
                        <div class="col-md-6">
                            <div
                                class="pricing-box rounded-5 pricing-simple p-5 bg-light border text-center text-md-start">
                                <div class="d-flex flex-column flex-lg-row justify-content-between mb-4">
                                    <div>
                                        <h3 class="mb-0 h3" style="line-height: 1">17€</h3>
                                        <small>Paiement unique</small>
                                        <hr>
                                        <h3 class="mb-3">Pack Essentiel
                                        </h3>
                                        <div class="line border-width-4 border-dark p-0 m-0 w-25 mx-auto mx-md-0 mb-4">
                                        </div>
                                        <ul class="iconlist iconlist-lg" style="--cnvs-iconlist-offset:1px;">
                                            <li><i class="bi-check-circle-fill color-green"></i> <span>Guide
                                                    complet</span></li>
                                            <li><i class="bi-check-circle-fill color-green"></i>
                                                <span>Questionnaire</span>
                                            </li>
                                            <li><i class="fa-solid fa-close color-red"></i> <span>Template
                                                    exclusifs</span></li>
                                            <li><i class="fa-solid fa-close color-red"></i> <span>Checklist
                                                    pratique</span></li>
                                            <li><i class="fa-solid fa-close color-red"></i> <span>Newsletter VIP</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <a (click)="checkout('essentiel')" *ngIf="checkoutButton === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                                    <a *ngIf="checkoutButtonLoading === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><img
                                            src="assets/images/loading.gif" width="20px"></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div
                                class="pricing-box rounded-5 pricing-simple p-5 bg-light border text-center text-md-start">
                                <div class="d-flex flex-column flex-lg-row justify-content-between mb-4">
                                    <div>
                                        <h3 class="mb-0 h3" style="line-height: 1">27€</h3>
                                        <small>Paiement unique</small>
                                        <hr>
                                        <h3 class="mb-3">Pack Premium</h3>
                                        <div class="line border-width-4 border-dark p-0 m-0 w-25 mx-auto mx-md-0 mb-4">
                                        </div>
                                        <ul class="iconlist iconlist-lg" style="--cnvs-iconlist-offset:1px;">
                                            <li><i class="bi-check-circle-fill color-green"></i> <span>Guide
                                                    complet</span></li>
                                            <li><i class="bi-check-circle-fill color-green"></i>
                                                <span>Questionnaire</span>
                                            </li>
                                            <li><i class="bi-check-circle-fill color-green"></i> <span>Template
                                                    exclusifs</span></li>
                                            <li><i class="bi-check-circle-fill color-green"></i> <span>Checklist
                                                    pratique</span></li>
                                            <li><i class="bi-check-circle-fill color-green"></i> <span>Newsletter
                                                    VIP</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <a (click)="checkout('Premium')" *ngIf="checkoutButton === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                                    <a *ngIf="checkoutButtonLoading === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><img
                                            src="assets/images/loading.gif" width="20px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section-faq">
    <div class="content-wrap py-0">
        <div id="section-faqs" class="page-section my-5">
            <div class="container">
                <div class="row justify-content-between position-relative">
                    <div class="col-lg-5">
                        <h3 class="display-3 ls-n-3 fw-bolder mb-3">
                            <span class="color-important-word">Questions</span>
                            Fréquemment Posées
                        </h3>
                        <img src="assets/images/ebooks/ebook06/book-07.jpg" alt="...">
                    </div>
                    <div class="col-lg-6 bg-contrast-0 p-5 rounded-xxl pt-6">
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    À qui s’adresse cet eBook ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Cet eBook est conçu pour toute personne souhaitant transformer son intérieur et trouver
                                son style, que vous soyez novice en décoration ou que vous ayez déjà des idées mais que
                                vous ayez besoin d’une méthode pour les structurer.
                            </div>
                        </div>

                        <div class="line border-contrast-300 my-4"></div>

                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Ai-je besoin d’être créatif pour utiliser cet eBook ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Pas du tout! Ce guide vous accompagne pas à pas avec une méthode simple, même si vous
                                pensez
                                ne pas avoir l’âme d’un décorateur.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Et si je n’ai aucune expérience en déco ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Pas de souci! Ce guide est spécialement conçu pour les débutants. Il vous accompagne pas
                                à pas
                                avec des explications simples et des exercices pratiques pour structurer vos idées et
                                réussir votre
                                projet déco, même si vous partez de zéro.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce adapté si j’ai un budget limité ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Absolument! Ce guide vous aide à maximiser vos idées tout en respectant vos contraintes
                                budgétaires, en évitant les achats inutiles et en favorisant une approche rééchie.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment accéder à l’eBook une fois acheté ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Une fois votre achat confirmé, vous recevrez un lien de téléchargement directement dans
                                votre boîte mail. Vous pourrez ainsi le consulter immédiatement.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de temps faut-il pour voir des résultats ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Cela dépend de votre rythme et de l'ampleur de votre projet, mais beaucoup de lecteurs
                                voient des
                                progrès dès les premières étapes du guide. Vous avancerez à votre rythme, avec une
                                méthode claire
                                et motivante.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Puis-je utiliser cet eBook pour une seule pièce ou tout mon intérieur ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Vous pouvez l’utiliser pour une pièce spécifique comme pour harmoniser l’ensemble de
                                votre maison. La méthode est flexible et s’adapte à tous vos besoins déco.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Y a-t-il des exemples ou des visuels dans l’eBook ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Oui! Le guide inclut des exemples concrets, des illustrations et des conseils visuels
                                pour vous aider
                                à mieux comprendre chaque étape et à vous inspirer dans vos choix.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</section>


<app-simulator-footer></app-simulator-footer>